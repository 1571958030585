import React from 'react'
import UsersList from '../../pages/Users/List'
import UsersUpdate from '../../pages/Users/Update'
import UsersCreate from '../../pages/Users/Create'
import UsersView from '../../pages/Users/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const UsersRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute path="/users" exact component={UsersList} />
    <PrivateRoute path="/users/create" component={UsersCreate} exact />
    <PrivateRoute path="/users/view/:id" component={UsersView} exact />
    <PrivateRoute path="/users/update/:id" component={UsersUpdate} exact />
  </CustomSwitch>
)
