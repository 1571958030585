import React, { useCallback, useEffect, useRef, useState } from 'react'
import logo from '../../../assets/media/logos/logo-b2b.png'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  WrapperContent,
  ContractText
} from './styles'
import { useLoading } from '../../../hooks/loading'
import api from '../../../services/api'
import { useToast } from '../../../hooks/toast'
import { genericMaskWithTwoZeroWithPoint } from '../../../utlis/mask'
import { Loading } from '../../../components/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import { Stepper } from '../../../components/Stepper'
import valueFull from 'extenso'
import axios from 'axios'

type ClientData = {
  id?: string
  full_name?: string
  cpf_cnpj?: string
  rg_partner?: string
  type?: 'PF' | 'PJ'
  street?: string
  district?: string
  city?: string
  state?: string
  phone?: string
  email?: string
  zip_code?: string
  card_number?: string
  card_flag?: string
  expiration_date?: string
  desired_value?: string
  number_installments?: number
  photo_card_front?: string
  photo_card_verse?: string
  photo_selfie_document?: string
  photo_document_1?: string
  photo_document_2?: string
  observation?: string
  status?: 'Novo' | 'Em Andamento' | 'Cancelado' | 'Finalizado'
  site?: string
  instagram?: string
  whatsapp?: string
  steps?: any
  full_name_partner?: string
  cpf_partner?: string
}

export const Contract: React.FC = () => {
  const contractTextRef = useRef(null)
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const { activeLoading, disableLoading, loading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const location = useLocation<ClientData>()
  const [desiredValueFull, setDesiredValueFull] = useState('')
  const [desiredValue, setDesiredValue] = useState('')
  const [locationData, setLocationData] = useState<{ city: string }>()
  const todayDate = new Date().toLocaleDateString('pt-BR', {
    month: '2-digit',
    year: '2-digit'
  })
  const todayDateFull = new Date().toLocaleDateString('pt-BR', {
    dateStyle: 'medium'
  })

  const handleSubmit = useCallback(async () => {
    const data: ClientData = {
      ...location.state
    }
    delete data.steps
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'object') {
        formData.append(key, value[0])
        return
      }
      if (value) {
        formData.append(key, String(value))
      }
    })
    try {
      activeLoading()
      await api.post('/commercial/public/clients', formData)
      disableLoading()
      addToast({
        type: 'success',
        title: 'Registro criado',
        description: 'Registro criado com sucesso'
      })
      history.push('/')
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar o registro',
        description:
          'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
      })
      disableLoading()
    }
  }, [activeLoading, addToast, disableLoading, history, location.state])

  async function getLocationData() {
    activeLoading()
    try {
      const response = await axios.get('https://api.ipify.org?format=json')
      const { data } = await api.get(`http://ipwho.is/${response.data.ip}`)
      disableLoading()
      setLocationData(data)
    } catch (error) {
      disableLoading()
    }
  }
  function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    const bottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
      event.currentTarget.clientHeight
    if (bottom) {
      setShowCheckbox(bottom)
    }
  }

  useEffect(() => {
    if (location.state) {
      const { desired_value } = location.state
      setDesiredValueFull(
        valueFull(genericMaskWithTwoZeroWithPoint(desired_value), {
          mode: 'currency'
        })
      )
      setDesiredValue(genericMaskWithTwoZeroWithPoint(desired_value))
    }
  }, [location?.state])
  useEffect(() => {
    getLocationData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckbox = useCallback(() => {
    if (!showCheckbox) {
      addToast({
        type: 'info',
        title: 'Leia o Termo até o final',
        description: 'Leia o Termo até o final para poder aceitá-lo'
      })
    }
  }, [addToast, showCheckbox])
  const handleButton = useCallback(() => {
    if (!showButton) {
      addToast({
        type: 'info',
        title: 'Leia o Termo até o final',
        description: 'Leia o Termo até o final para poder aceitá-lo'
      })
    }
  }, [addToast, showButton])

  return (
    <div>
      <Loading isActive={loading} />
      <div className="row gy-5 g-xl-8">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="h-55px w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="row gy-5 g-xl-8 w-100">
          <div className="card p-10">
            <Stepper
              steps={[
                {
                  status: location?.state.steps['1'],
                  step: '1',
                  title: 'Simulação'
                },
                {
                  status: location?.state.steps['2'],
                  step: '2',
                  title: 'Dados Pessoais'
                },
                {
                  status: location?.state.steps['3'],
                  step: '3',
                  title: 'Dados do Cartão'
                },
                {
                  status: location?.state.steps['4'],
                  step: '4',
                  title: 'Termos e Condições'
                }
              ]}
            />
            <h1>Termos e Condições</h1>
            <ContractText
              onScroll={handleScroll}
              className="mb-8"
              ref={contractTextRef}
            >
              <p style={{ textAlign: 'center' }}>
                CONTRATO DE PRESTA&Ccedil;&Atilde;O DE SERVI&Ccedil;OS DE
                PROFISSIONAL
              </p>
              <br />

              <br />
              <p>
                CONTRATANTE: {location?.state?.full_name}, Inscrita na
                Secretaria da Receita Federal sob n&deg; CPF/CNPJ
                {` ${location?.state?.cpf_cnpj}`} e sede administrativa na rua{' '}
                {''}
                {location?.state?.district}, {location?.state?.city},{' '}
                {location?.state?.state}, CEP {location?.state?.zip_code}. {''}
                {location.state.rg_partner
                  ? `Neste
                ato representado pelo Sr. ${location?.state.full_name_partner}, portador do RG nº
                ${location?.state.rg_partner}, inscrito no CPF º ${location?.state?.cpf_partner}.`
                  : ''}
              </p>
              <br />

              <br />
              <p>
                CONTRATADO: XXXXXXXXXXXXXXXXXXX, XXXXXXXX, XXXXXXXXXXXXXXXXX,
                inscrito no CPF/CNPJ sob o n&ordm; XXXXXXXXXXXXXXX, portador da
                CI n&ordm; XXXXXXXXXXX, residente e domiciliado na
                XXXXXXXXXXXXXXXXXXX, XXXXXXXXXXXX, XXXXXXXXXX, XXXXXXXXXX, CEP:
                XXXXXXXXXXX.
              </p>
              <br />
              <p>
                Pelo presente instrumento particular de presta&ccedil;&atilde;o
                de servi&ccedil;os de profissional de Consultoria em T.I e
                Intermedia&ccedil;&atilde;o de Pagamento, t&ecirc;m entre si
                justos e acordados quanto segue:
              </p>
              <br />

              <p style={{ textAlign: 'center' }}>DO OBJETO DO CONTRATO</p>
              <br />
              <p>
                CL&Aacute;USULA 1&ordf;: O presente instrumento tem como objeto
                a presta&ccedil;&atilde;o de servi&ccedil;os de Consultoria de
                Tecnologia da Informa&ccedil;&atilde;o e
                Intermedia&ccedil;&atilde;o de pagamento, relacionadas com;
                sistemas, plataformas, automa&ccedil;&atilde;o e
                transa&ccedil;&otilde;es financeiras por meios
                eletr&ocirc;nicos, mediante as solu&ccedil;&otilde;es de
                tecnologias, inova&ccedil;&atilde;o e tratamento de dados, bem
                como apoio t&eacute;cnico na &aacute;rea de telecom. al&eacute;m
                de seguran&ccedil;a da informa&ccedil;&atilde;o.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>DOS SERVI&Ccedil;OS</p>

              <br />
              <p>
                CL&Aacute;USULA 2&ordf;: O Contratado prestar&aacute; os
                servi&ccedil;os diretamente &agrave; Contratante buscando e
                disponibilizando as solu&ccedil;&otilde;es que atenda aos
                interesses dela, tais como; implementa&ccedil;&atilde;o de
                plataformas, sistema de gest&atilde;o e projetos e interfaces de
                integra&ccedil;&atilde;o. Desde que habilitadas junto ao
                Contratante, e, estejam dentro das regras, diretrizes e
                processos estabelecidos pelo Banco Central do Brasil - BACEN e
                Telecom, tendo como foco a inova&ccedil;&atilde;o,
                automa&ccedil;&atilde;o e telecomunica&ccedil;&atilde;o.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>
                OBRIGA&Ccedil;&Otilde;ES DA CONTRATANTE
              </p>

              <br />
              <p>
                CL&Aacute;USULA 3&ordf;: Fica estabelecido como
                obriga&ccedil;&otilde;es da Contratante:
              </p>

              <br />
              <p>
                3.1 Efetuar o pagamento pontualmente e seguindo os termos
                acordados na cl&aacute;usula 5&ordm;.
              </p>

              <br />
              <p>
                3.2 Fornece ao Contratado toda e qualquer
                informa&ccedil;&atilde;o t&eacute;cnica necess&aacute;ria acerca
                das empresas interessadas no desenvolvimento do projeto de
                tecnologia, independente da &aacute;rea de
                atua&ccedil;&atilde;o.
              </p>

              <br />
              <p>
                3.3 Fornece ao Contratado todo e qualquer material e
                informa&ccedil;&atilde;o necess&aacute;ria que possibilite a
                presta&ccedil;&atilde;o do servi&ccedil;o contratado,
                facilitando a prospec&ccedil;&atilde;o dos neg&oacute;cios.
              </p>

              <br />
              <p>
                3.4 Ser&aacute; vedado a Contratante negociar abatimentos,
                descontos ou dila&ccedil;&otilde;es de prazo para o pagamento a
                execu&ccedil;&atilde;o dos servi&ccedil;os, sem pr&eacute;via
                anu&ecirc;ncia do Contratado.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>
                OBRIGA&Ccedil;&Otilde;ES DO CONTRATADO
              </p>

              <br />
              <p>
                CL&Aacute;USULA 4&ordf;: S&atilde;o obriga&ccedil;&otilde;es do
                Contratado:
              </p>

              <br />
              <p>
                1. Cumprir as disposi&ccedil;&otilde;es contidas no contrato.
              </p>

              <br />
              <p>
                4.2 Seguir estritamente as orienta&ccedil;&otilde;es e
                instru&ccedil;&otilde;es da Contratante, sobre os termos da
                presta&ccedil;&atilde;o dos servi&ccedil;os realizados aos seus
                clientes.
              </p>

              <br />
              <p>
                4.3 Prestar informa&ccedil;&otilde;es &agrave; Contratante,
                diariamente ou sempre que lhe for solicitado, informando sobre a
                execu&ccedil;&atilde;o dos servi&ccedil;os e demais detalhes
                requisitados sobre a execu&ccedil;&atilde;o das atividades
                contratadas.
              </p>

              <br />
              <p>
                4.4 Manter o sigilo de todas as informa&ccedil;&otilde;es
                prestadas pela Contratante e que sejam inerentes aos seus
                Clientes e ao seu neg&oacute;cio, bem como estrat&eacute;gias
                mantendo a confidencialidade de todas as
                informa&ccedil;&otilde;es da empresa.
              </p>

              <br />
              <p>
                4.5 N&atilde;o intermediar abatimentos, descontos, ou
                dila&ccedil;&atilde;o de prazo sem expressa
                autoriza&ccedil;&atilde;o da Contratante.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>DO PAGAMENTO</p>

              <br />
              <p>
                CL&Aacute;USULA 5&ordf;: O Contratado receber&aacute; o valor de
                R$ {desiredValue} ({desiredValueFull}) como
                contrapresta&ccedil;&atilde;o, sendo que o pagamento realizado
                na data {''}
                {todayDate} a empresa contratada
              </p>

              <br />
              <p>
                5.1- Nenhum outro benef&iacute;cio ser&aacute; pago ao
                Contratado, inclusive de deslocamento.
              </p>

              <br />
              <p>
                5.2- O primeiro pagamento previsto ser&aacute; realizado no dia{' '}
                {todayDate} e os demais nos meses subsequentes.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>DA RESCIS&Atilde;O</p>
              <br />
              <p>
                CL&Aacute;USULA 6&ordf;: S&atilde;o motivos de rescis&atilde;o
                por parte da Contratante:
              </p>

              <br />
              <p>
                6.1- Des&iacute;dia do Contratado no cumprimento das
                obriga&ccedil;&otilde;es assumidas com a Contratante.
              </p>

              <br />
              <p>
                6.2- Praticar atos que atinjam a imagem comercial da Contratante
                perante terceiros.
              </p>

              <br />
              <p>
                6.3 - Deixar de cumprir o Contratado, qualquer uma das
                cl&aacute;usulas dispostas no presente instrumento
              </p>

              <br />
              <p>
                PAR&Aacute;GRAFO &Uacute;NICO: A rescis&atilde;o do presente
                instrumento n&atilde;o extingue os direitos e as
                obriga&ccedil;&otilde;es que as partes tenham entre si e
                contra&iacute;das perante terceiros.
              </p>

              <br />
              <p>
                CL&Aacute;USULA 7&ordf;: S&atilde;o motivos de rescis&atilde;o
                por parte do Contratado:
              </p>

              <br />
              <p>
                7.1 - Solicitar a Contratante, qualquer atividade n&atilde;o
                esteja prevista no contrato.
              </p>

              <br />
              <p>
                7.2- Deixar a Contratante de observar ou descumprir quaisquer
                obriga&ccedil;&otilde;es presentes no contrato.
              </p>

              <br />
              <p>
                7.3- O n&atilde;o pagamento pontual dos valores acordados na
                Cl&aacute;usula 5&ordm;.
              </p>

              <br />
              <p>
                7.4 - Por motivos de for&ccedil;a maior, que impe&ccedil;am a
                continuidade do servi&ccedil;o contratado.
              </p>

              <br />
              <p>
                PAR&Aacute;GRAFO &Uacute;NICO: A rescis&atilde;o do presente
                instrumento de contrato n&atilde;o extingue os direitos e
                obriga&ccedil;&otilde;es que as partes tenham entre si e
                contra&iacute;das perante terceiros.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>DA VIG&Ecirc;NCIA</p>
              <br />
              <p>
                CL&Aacute;USULA 8&ordf;: O presente contrato ter&aacute;
                vig&ecirc;ncia de 12 meses, sendo renovado por igual
                per&iacute;odo de forma autom&aacute;tica. Caso n&atilde;o
                exista interesse na renova&ccedil;&atilde;o a parte interessada
                notificar&aacute; a parte contraria, por escrito, com
                anteced&ecirc;ncia m&iacute;nima de 30 (trinta) dias para o fim
                do per&iacute;odo previsto.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>DA PENALIDADE</p>

              <br />
              <p>
                CL&Aacute;USULA 9&ordf;: Em caso de rescis&atilde;o realizada
                por qualquer uma das partes fora das hip&oacute;teses previstas
                neste contrato, implicar&aacute; na aplica&ccedil;&atilde;o de
                multa no valor de 20%. A ser calculada sobre os valores
                previstos na cl&aacute;usula 5&ordm; e tendo como base o
                per&iacute;odo remanescente do contrato.
              </p>

              <br />
              <p style={{ textAlign: 'center' }}>DO FORO</p>

              <br />
              <p>
                CL&Aacute;USULA 10&ordf;: As partes elegem o Foro da Comarca de
                Goi&acirc;nia, para dirimir judicialmente as
                controv&eacute;rsias inerentes ao presente contrato.
              </p>

              <br />
              <p>
                E assim por estarem justo e certo das obriga&ccedil;&otilde;es
                aqui previstas as Partes assinam o presente instrumento em 02
                (duas) vias de igual teor, seguido das assinaturas de duas
                testemunhas, reconhecendo assim a for&ccedil;a executiva do
                presente t&iacute;tulo.
              </p>

              <br />
              <p>
                {' '}
                {locationData?.city}, {todayDateFull}.
              </p>
            </ContractText>
            <div>
              <div
                className="form-check form-check-sm form-check-custom form-check-solid me-3"
                onClick={handleCheckbox}
              >
                <input
                  className="form-check-input"
                  style={{
                    marginRight: '5px'
                  }}
                  disabled={!showCheckbox}
                  type="checkbox"
                  onChange={event => setShowButton(event.target.checked)}
                />
                <span>Aceito os Termos e Condições desse Contrato</span>
              </div>
            </div>
            <div>
              <div className="row d-flex justify-content-end py-6 px-9">
                <div className="col-md-3 d-flex justify-content-end">
                  <div onClick={handleButton}>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      disabled={!showButton}
                      className="btn btn-primary btn-lg"
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerWrapperContent>
    </div>
  )
}

export default Contract
