import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import logo from '../../../assets/media/logos/logo-b2b.png'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  WrapperContent
} from './styles'
import Form, { Input, Select } from '../../../components/Form'
import { useLoading } from '../../../hooks/loading'
import {
  cardMask,
  convertValueMaskInNumberWithTwoZero,
  genericMaskWithTwoZero,
  genericMaskWithTwoZeroWithPoint
} from '../../../utlis/mask'
import { Date as DatePicker } from '../../../components/Form/date'
import { Loading } from '../../../components/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import { Stepper } from '../../../components/Stepper'
import api from '../../../services/api'
import currency from 'currency.js'
import { useToast } from '../../../hooks/toast'
import { IMaskInput, IMask } from 'react-imask'
import moment from 'moment'

type ClientData = {
  id?: string
  full_name?: string
  cpf_cnpj?: string
  rg_partner?: string
  type?: 'PF' | 'PJ'
  street?: string
  district?: string
  city?: string
  state?: string
  phone?: string
  email?: string
  zip_code?: string
  card_number?: string
  card_flag?: string
  expiration_date?: string
  desired_value?: string
  number_installments?: number
  photo_card_front?: string
  photo_card_verse?: string
  photo_selfie_document?: string
  photo_document_1?: string
  photo_document_2?: string
  observation?: string
  status?: 'Novo' | 'Em Andamento' | 'Cancelado' | 'Finalizado'
  site?: string
  instagram?: string
  whatsapp?: string
  number?: string
  complement?: string
  operation_total?: string
  fees?: string
  installment_value?: string
  amount_receivable?: string
}

type OperationIndexData = {
  id?: string
  title?: string
  index?: string
  number_installments?: string
  requested_amount?: string
}

export const Card: React.FC = () => {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const { loading, activeLoading, disableLoading } = useLoading()
  const location = useLocation<any>()
  const history = useHistory()
  const { addToast } = useToast()
  const [defaultedValues, setDefaultedValues] = useState<ClientData>()
  const [photos, setPhotos] = useState<File[]>([])
  const [expirateDate, setExpirateDate] = useState<Date>()
  const [requestedAmount, setRequestedAmount] = useState<string>('0,00')
  const [operationTotal, setOperationTotal] = useState('0,00')
  const [fees, setFees] = useState('0,00')
  const [installmentValue, setInstallmentValue] = useState('0,00')
  const [amountReceivable, setAmountReceivable] = useState('0,00')
  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardFlag, setCardFlag] = useState<string>('')
  const cards: any = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Mastercard: /^5[1-5][0-9]{14}/,
    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Amex: /^3[47][0-9]{13}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    Aura: /^(5078\d{2})(\d{2})(\d{11})$/
  }
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })
  const onSubmit = useCallback(
    async (data: ClientData) => {
      data = {
        ...data,
        ...location.state,
        operation_total: operationTotal,
        fees: fees,
        installment_value: installmentValue,
        amount_receivable: amountReceivable,
        status: 'Novo'
      }
      data.desired_value = genericMaskWithTwoZero(
        data.desired_value
      ).replaceAll(',', '.')
      data.operation_total = genericMaskWithTwoZero(
        data.operation_total
      ).replaceAll(',', '.')
      data.fees = genericMaskWithTwoZero(data.fees).replaceAll(',', '.')
      data.installment_value = genericMaskWithTwoZero(
        data.installment_value
      ).replaceAll(',', '.')
      data.amount_receivable = genericMaskWithTwoZero(
        data.amount_receivable
      ).replaceAll(',', '.')
      if (typeof data.expiration_date === 'object') {
        data.expiration_date = Intl.DateTimeFormat('en-US', {
          month: '2-digit',
          year: '2-digit'
        }).format(new Date(expirateDate))
      }
      history.push({
        pathname: '/simulation/contract',
        state: {
          ...data,
          steps: {
            1: 'completed',
            2: 'completed',
            3: 'completed',
            4: 'current'
          }
        }
      })
    },
    [
      amountReceivable,
      expirateDate,
      fees,
      history,
      installmentValue,
      location.state,
      operationTotal
    ]
  )

  const removeDuplicated = (data: any[]) => {
    const unique: any[] = []
    const filteredItems = data.filter(item => {
      const isDuplicated = unique.includes(item.name)
      if (!isDuplicated) {
        unique.push(item.name)
        return true
      }
      return false
    })
    return filteredItems
  }

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]
    const copyFilesUploads = [...photos, file]
    const filterFilesUploads: File[] = removeDuplicated(copyFilesUploads)
    setPhotos(filterFilesUploads)
  }

  useEffect(() => {
    if (location.state) {
      setDefaultedValues({
        ...location.state,
        desired_value: genericMaskWithTwoZeroWithPoint(
          location.state.desired_value || '0,00'
        ),
        operation_total: genericMaskWithTwoZeroWithPoint(
          location.state.operation_total || '0,00'
        ),
        fees: genericMaskWithTwoZeroWithPoint(location.state.fees || '0,00'),
        installment_value: genericMaskWithTwoZeroWithPoint(
          location.state.installment_value || '0,00'
        ),
        amount_receivable: genericMaskWithTwoZeroWithPoint(
          location.state.amount_receivable || '0,00'
        )
      })
      setRequestedAmount(
        genericMaskWithTwoZeroWithPoint(location.state.desired_value || '0,00')
      )
      setOperationTotal(
        genericMaskWithTwoZeroWithPoint(
          location.state.operation_total || '0,00'
        )
      )
      setFees(genericMaskWithTwoZeroWithPoint(location.state.fees || '0,00'))
      setInstallmentValue(
        genericMaskWithTwoZeroWithPoint(
          location.state.installment_value || '0,00'
        )
      )
      setAmountReceivable(
        genericMaskWithTwoZeroWithPoint(
          location.state.amount_receivable || '0,00'
        )
      )
    }
  }, [location.state])

  const handleSimulation = useCallback(
    async (number_installments?: string) => {
      if (!requestedAmount || requestedAmount === '0,00') {
        return
      }
      const copyNumberInstallments =
        number_installments || location.state?.number_installments
      if (!copyNumberInstallments) {
        return
      }
      activeLoading()
      try {
        const { data } = await api.get<OperationIndexData>(
          `/financial/public/operationIndexes/findByNumberInstallment/${copyNumberInstallments}`
        )
        const index = Number(data.index.replaceAll(',', '.'))
        const numberRequestedAmount =
          convertValueMaskInNumberWithTwoZero(requestedAmount)
        const totalValue = numberRequestedAmount * index
        setOperationTotal(BRL(totalValue).format())
        setFees(BRL(totalValue).subtract(numberRequestedAmount).format())
        setInstallmentValue(
          BRL(totalValue).divide(Number(copyNumberInstallments)).format()
        )
        setAmountReceivable(BRL(requestedAmount).format())
        disableLoading()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar indice',
          description:
            'Ocorreu um erro ao buscar o indice, por favor, tente novamente.'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      location.state?.number_installments,
      requestedAmount
    ]
  )

  const handleFlagCard = useCallback((value: string) => {
    setCardNumber(cardMask(value))
    const cardNumber = value.replace(/[^0-9]+/g, '')
    for (const flag in cards) {
      if (cards[flag].test(cardNumber)) {
        setCardFlag(flag)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (requestedAmount) {
      timer = setTimeout(() => {
        handleSimulation()
      }, 500)
    } else {
      setRequestedAmount('0,00')
      setOperationTotal('0,00')
      setFees('0,00')
      setInstallmentValue('0,00')
      setAmountReceivable('0,00')
    }
    return () => clearTimeout(timer)
  }, [handleSimulation, requestedAmount])

  return (
    <div>
      <Loading isActive={loading} />
      <div className="row gy-5 g-xl-8">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="h-55px w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="row gy-5 g-xl-8 w-100">
          <div className="card p-10">
            <Stepper
              steps={[
                {
                  status: location?.state.steps['1'],
                  step: '1',
                  title: 'Simulação'
                },
                {
                  status: location?.state.steps['2'],
                  step: '2',
                  title: 'Dados Pessoais'
                },
                {
                  status: location?.state.steps['3'],
                  step: '3',
                  title: 'Dados do Cartão'
                },
                {
                  status: location?.state.steps['4'],
                  step: '4',
                  title: 'Termos e Condições'
                }
              ]}
            />
            <h1>Dados Cartão</h1>
            <div className="form">
              <Form
                className="form"
                onSubmit={onSubmit}
                defaultValues={defaultedValues}
              >
                <div className="row mb-5">
                  <Input
                    name="card_number"
                    className="col-md-3"
                    label="Número do Cartão"
                    rules={{ required: true }}
                    maxLength={19}
                    onChange={event => handleFlagCard(event.target.value)}
                    value={cardNumber}
                  />
                  <Input
                    name="card_flag"
                    className="col-md-3"
                    label="Bandeira do Cartão"
                    value={cardFlag}
                    rules={{ required: !cardFlag.length }}
                    onChange={event => setCardFlag(event.target.value)}
                  />
                  <DatePicker
                    locale="en"
                    name="expiration_date"
                    className="col-md-3"
                    label="Data de Validade"
                    dateFormat="MM/yy"
                    placeholderText="MM/AA"
                    selected={expirateDate}
                    minDate={new Date()}
                    required
                    customInput={
                      <IMaskInput
                        mask={Date}
                        pattern="MM/YY"
                        format={date => {
                          return moment(date).format('MM/YY')
                        }}
                        parse={value => {
                          return moment(value, 'MM/YY').toDate()
                        }}
                        validate={(date: string) => {
                          if (date.length === 5) {
                            return moment(date, 'MM/YY').isSameOrAfter(
                              moment(),
                              'month'
                            )
                          }
                          return true
                        }}
                        maxLength={5}
                        blocks={{
                          MM: {
                            mask: IMask.MaskedRange,
                            placeholderChar: 'MM',
                            from: 1,
                            to: 12,
                            autofix: true,
                            maxLength: 2
                          }
                        }}
                      />
                    }
                    onChange={date => {
                      setExpirateDate(date)
                    }}
                    showMonthYearPicker
                    controlled
                  />
                </div>
                <div className="row mb-5">
                  <Input
                    name="desired_value"
                    className="col-md-3"
                    label="Valor desejado"
                    rules={{ required: true }}
                    onChange={event =>
                      setRequestedAmount(
                        genericMaskWithTwoZeroWithPoint(event.target.value)
                      )
                    }
                    value={requestedAmount}
                  />
                  <Select
                    className="col-md-3"
                    name="number_installments"
                    label="Quantidades de Parcelas"
                    options={months.map(
                      month =>
                        month && {
                          name: month,
                          value: month
                        }
                    )}
                    onChange={event => handleSimulation(event.target.value)}
                    blank
                    defaultValue={''}
                    rules={{ required: true }}
                  />
                  <Input
                    label="Total da Operação"
                    name="operation_total"
                    className="col-md-3"
                    value={operationTotal}
                    readOnly
                  />
                  <Input
                    label="Juros"
                    name="fees"
                    value={fees}
                    className="col-md-3"
                    readOnly
                  />
                  <Input
                    label="Valor Parcela"
                    className="col-md-3"
                    name="installment_value"
                    value={installmentValue}
                    readOnly
                  />
                  <Input
                    label="Valor a Receber"
                    className="col-md-3"
                    name="amount_receivable"
                    value={amountReceivable}
                    readOnly
                  />
                </div>
                <div className="row mb-5">
                  <Input
                    label="Foto do Cartão Frente"
                    name="photo_card_front"
                    className="col-md-5"
                    type="file"
                    onChange={onChangeFileHandler}
                    rules={{ required: true }}
                  />
                  <Input
                    name="photo_card_verse"
                    className="col-md-5"
                    label="Foto do Cartão Verso"
                    rules={{ required: true }}
                    type="file"
                    onChange={onChangeFileHandler}
                  />

                  <Input
                    name="photo_document_1"
                    className="col-md-5"
                    label="Foto Documento 1"
                    rules={{ required: true }}
                    type="file"
                    onChange={onChangeFileHandler}
                  />
                  <Input
                    name="photo_document_2"
                    className="col-md-5"
                    label="Foto Documento 2"
                    type="file"
                    rules={{ required: true }}
                    onChange={onChangeFileHandler}
                  />
                  <Input
                    name="photo_selfie_document"
                    className="col-md-5"
                    label="Foto Selfie Documento"
                    rules={{ required: true }}
                    type="file"
                    onChange={onChangeFileHandler}
                  />
                </div>
                <div className="row d-flex justify-content-end py-6 px-9">
                  <div className="col-md-3 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Próximo
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </ContainerWrapperContent>
    </div>
  )
}

export default Card
