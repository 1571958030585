import styled, { css, keyframes } from 'styled-components'

interface ContainerMenuMobile {
  isShow: string
}

const topToBottom = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const ContainerHeader = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgb(12 0 46 / 6%);
  height: 64px;
`

export const WrapperContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding: 0 15px;
`

export const ContainerLogo = styled.div``

export const ContainerWrapperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 15px;
`

export const ContainerMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
export const ContainerMenuMobile = styled.div<ContainerMenuMobile>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 0px;
  transition: height 0.7s ease-in-out;
  clear: both;
  opacity: 0;
  position: absolute;
  margin-top: 6.7rem;
  margin-left: 9px;
  overflow: hidden;
  padding: 0;
  will-change: height;
  ${props => {
    if (props.isShow === 'show') {
      return css`
        width: 100%;
        position: absolute;
        background-color: #fff;
        opacity: 1;
        height: 160px;
        margin-top: 6.7rem;
        margin-left: 9px;
        z-index: 1;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
      `
    }
    if (props.isShow === 'hidden') {
      return css`
        animation: ${topToBottom} 1s ease-in-out;
      `
    }
  }}
`

export const Link = styled.div`
  & + div {
    margin-left: 5px;
  }
`
export const Button = styled.button`
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 15px 30px !important;
`
export const ContainerBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  width: 100%;
  > img {
    margin-top: -12rem;
    @media (max-width: 845px) {
      margin-top: 3rem;
    }
  }
`
export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  h2 {
    font-size: 40px;
    & + h2 {
      margin-bottom: 30px;
    }
  }
`
export const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 230px;
  & + div {
    margin-top: 130px;
  }
  h3 {
    font-size: 26px;
    text-align: left;
    margin: 32px 0 25px;
  }
  p {
    display: flex;
    text-align: left;
    max-width: 400px;
  }
  div {
    padding: 0 70px;
  }
`
