import { Header } from '../../../../../components/DataTable'

export const headers: Header[] = [
  { name: 'Nome Completo', field: 'full_name', sortable: true },
  { name: 'CPF/CNPJ', field: 'cpf_cnpj', sortable: true },
  { name: 'Telefone', field: 'phone', sortable: true },
  { name: 'Tipo de Operação', field: 'operation_type', sortable: true },
  { name: 'Valor Desejado', field: 'desired_value', sortable: true },

  {
    name: 'Status',
    field: 'status',
    sortable: true
  },
  {
    name: 'Cadastrado em',
    field: 'created_at',
    sortable: true,

    includesFields: [
      {
        field: 'created_at',
        value: 'TOTAL',
        style: {
          fontWeight: 'bold'
        }
      }
    ]
  },
  {
    name: 'Total Da Operação',
    field: 'operation_total',
    sortable: true,
    includesFields: [
      {
        field: 'created_at',
        value: 'TOTAL',
        style: {
          fontWeight: 'bold'
        }
      }
    ]
  },
  {
    name: 'Ações',
    field: 'actions',
    sortable: false,
    excludesFields: [
      {
        field: 'created_at',
        value: 'TOTAL'
      }
    ]
  }
]
