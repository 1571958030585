import styled from 'styled-components'

export const ContainerHeader = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgb(12 0 46 / 6%);
  margin-bottom: 100px;
  height: 64px;
`

export const WrapperContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

export const ContainerLogo = styled.div``

export const ContainerWrapperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`
export const ContractText = styled.div`
  max-height: 50vh;
  overflow: auto;
`
