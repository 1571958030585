/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import { phoneMask } from '../../../../utlis/mask'
import { ModalImage } from './styles'

interface ClientData {
  id: string
  full_name?: string
  cpf_cnpj?: string
  rg_partner?: string
  type?: 'PF' | 'PJ'
  street?: string
  district?: string
  city?: string
  state?: string
  phone?: string
  email?: string
  zip_code?: string
  card_number?: string
  card_flag?: string
  expiration_date?: string
  desired_value?: string
  number_installments?: number
  photo_card_front?: string
  photo_card_verse?: string
  photo_selfie_document?: string
  photo_document_1?: string
  photo_document_2?: string
  photo_card_front_url?: string
  photo_card_verse_url?: string
  photo_selfie_document_url?: string
  photo_document_1_url?: string
  photo_document_2_url?: string
  photo_card_front_type?: string
  photo_card_verse_type?: string
  photo_selfie_document_type?: string
  photo_document_1_type?: string
  photo_document_2_type?: string
  payment_proof?: string
  payment_proof_url?: string
  payment_proof_type?: string
  observation?: string
  status?: 'Novo' | 'Em Andamento' | 'Cancelado' | 'Finalizado'
  site?: string
  instagram?: string
  whatsapp?: string
  created_at: string
  updated_at: string
  operation_total?: string
  fees?: string
  installment_value?: string
  amount_receivable?: string
  operation_type?: 'Micro Crédito' | 'Pagamento de Conta'
}

const ClientView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [client, setClient] = useState<ClientData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { disableLoading, activeLoading } = useLoading()
  const [imageModalFront, setImageModalFront] = useState(false)
  const [imageModalVerse, setImageModalVerse] = useState(false)
  const [imageModalDocument1, setImageModalDocument1] = useState(false)
  const [imageModalDocument2, setImageModalDocument2] = useState(false)
  const [imageModalDocumentSelfie, setImageModalDocumentSelfie] =
    useState(false)
  const [imageModalProofPayment, setImageModalProofPayment] = useState(false)
  const checkFileType = (file: string): string => {
    if (file.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i)) {
      return 'media'
    }
    return 'document'
  }
  useEffect(() => {
    activeLoading()
    async function loadClient(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<ClientData>(apiList(id))
        const { data } = response
        const copyData: any = { ...data }
        Object.entries(data).map(async ([key, value]) => {
          if (key.includes('url') && value) {
            const newKey = key.replaceAll('_url', '_type')
            const keyOrigin = key.replaceAll('_url', '')
            const type = checkFileType(value)
            value = value.substring(value.lastIndexOf('/') + 1)
            const indexTrace = value.indexOf('-') + 1
            const removedHash = value.slice(indexTrace, value.length)
            copyData[newKey] = type
            copyData[keyOrigin] = removedHash
          }
          return value
        })
        setClient(copyData)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o cliente',
          description:
            'Houve um error ao carregar o cliente, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadClient()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, disableLoading, history, location.pathname])
  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Cliente removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Cliente não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Cliente removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Cliente não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleDownload = useCallback(async (filename: string, url: string) => {
    const response = await fetch(url)
    const blob = await response.blob()

    const urlBlob = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = filename
    a.href = urlBlob
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }, [])
  return (
    <div className="d-flex flex-column container-fluid">
      <>
        <Container
          pageTitle={nameActions.read.name}
          breadcrumb={breadcrumbView}
          tools={[
            toolsViewUpdate(String(id)),
            toolsViewDelete(() => {
              handleOnClickRemoveParent()
            }),
            toolsViewCreate(),
            toolsViewList()
          ]}
        >
          <div className="form-body">
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="id"
                    className="col-form-label fw-bold fs-6 form-label fw-bold fs-6"
                  >
                    Cód.
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{client?.id}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="type"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Tipo
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{client?.type}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor="full_name"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Nome Completo
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.full_name}
                  </p>
                </div>
              </div>
            </div>
            {client?.type === 'PJ' && (
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="rg_partner"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      RG Sócio
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.rg_partner}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="rg_partner"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      CPF Sócio
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.rg_partner}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="rg_partner"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Nome Completo Sócio
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.rg_partner}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="cpf_cnpj"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    CPF/CNPJ
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.cpf_cnpj}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="phone"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Telefone
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {phoneMask(client?.phone || '')}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="whatsapp"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    WhatsApp
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.whatsapp}
                  </p>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="street"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Logradouro
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.street}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="district"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Bairro
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.district}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="city"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cidade
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.city} | {client?.state}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="zip_code"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    CEP
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.zip_code}
                  </p>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="site"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Site
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">{client?.site}</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="instagram"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Instagram
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.instagram}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="email"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    E-mail
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.email}
                  </p>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="created"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Cadastrado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.created_at}
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label
                    htmlFor="updated"
                    className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                  >
                    Atualizado em
                  </label>
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.updated_at}
                  </p>
                </div>
              </div>
            </div>

            <p className="fw-bolder fs-6 text-gray-800">&nbsp;</p>
          </div>
        </Container>

        <Alert
          message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
          onClickCancellButton={handlerClickButtonCancellAlert}
          onClickConfirmButton={() => {
            handlerClickButtonConfirmAlert(String(alert.id))
          }}
          isActive={alert.isActive}
        />
        <Alert
          message={`Tem certeza que deseja excluir o registro ${client?.full_name} ?`}
          onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveParent(Number(client?.id))
          }
          isActive={alertRemoveParent}
        />
      </>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Dados da Contratação</h1>
            <div className="form-body">
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Tipo de Operação
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.operation_type}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="desired_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Valor desejado
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.desired_value}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="number_installments"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Quantidades de Parcelas
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.number_installments}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label
                      htmlFor="operation_total"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Total da Operação
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.operation_total}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label
                      htmlFor="fees"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Juros
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.fees}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="card_number"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Número do Cartão
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.card_number}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="card_flag"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Bandeira do Cartão
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.card_flag}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="expiration_date"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Data de Validade
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.expiration_date}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="installment_value"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Valor Parcela
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.installment_value}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="amount_receivable"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Valor a Receber
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.amount_receivable}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label
                      htmlFor="status"
                      className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                    >
                      Status
                    </label>
                    <p className="fw-bolder fs-6 text-gray-800">
                      {client?.status}
                    </p>
                  </div>
                </div>
                {client?.payment_proof_type && (
                  <div
                    className="col"
                    style={{
                      minWidth: '350px'
                    }}
                  >
                    <div className="form-group d-flex flex-column">
                      <label
                        htmlFor="street"
                        className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                      >
                        Comprovante de Pagamento
                      </label>
                      {client?.payment_proof_type &&
                        (client?.payment_proof_type?.includes('media') ? (
                          <img
                            style={{
                              width: 350,
                              objectFit: 'cover',
                              height: 200
                            }}
                            alt=""
                            className="img-bordered"
                            src={client?.payment_proof_url || ''}
                            onClick={() => setImageModalProofPayment(true)}
                          />
                        ) : (
                          <>
                            <a
                              href="#"
                              onClick={() =>
                                handleDownload(
                                  client?.payment_proof,
                                  client?.payment_proof_url
                                )
                              }
                              style={{
                                display: 'flex',
                                alignItems: 'baseline'
                              }}
                            >
                              <span
                                className="fw-bolder fs-6"
                                style={{
                                  display: 'flex',
                                  alignSelf: 'end'
                                }}
                              >
                                Download
                              </span>
                              <span
                                className="fa fa-download"
                                style={{ marginLeft: '5px', fontSize: '14px' }}
                              ></span>
                            </a>
                          </>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-xl-10">
        <div className="card">
          <div className="card-body p-9">
            <h1>Anexos</h1>
            <div className="row d-flex flex-wrap justify-content-center align-items-center">
              <div
                className="my-10 col"
                style={{
                  minWidth: '350px'
                }}
              >
                {client?.photo_card_front_type?.includes('media') ? (
                  <img
                    style={{
                      width: 350,
                      objectFit: 'cover',
                      height: 200
                    }}
                    alt=""
                    className="img-bordered"
                    src={client?.photo_card_front_url || ''}
                    onClick={() => setImageModalFront(true)}
                  />
                ) : (
                  <>
                    <a
                      href="#"
                      onClick={() =>
                        handleDownload(
                          client?.photo_card_front,
                          client?.photo_card_front_url
                        )
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'baseline'
                      }}
                    >
                      <p
                        className="fw-bolder fs-6"
                        style={{
                          maxWidth: '50%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          margin: '0px 20px 0px 20px'
                        }}
                      >
                        {client?.photo_card_front}
                      </p>
                      <span
                        className="fa fa-download"
                        style={{ marginRight: '5px', fontSize: '14px' }}
                      ></span>
                    </a>
                  </>
                )}
              </div>
              <div
                className="my-10 col"
                style={{
                  minWidth: '350px'
                }}
              >
                {client?.photo_card_verse_type?.includes('media') ? (
                  <img
                    style={{
                      width: 350,
                      objectFit: 'cover',
                      height: 200
                    }}
                    alt=""
                    className="img-bordered"
                    src={client?.photo_card_verse_url || ''}
                    onClick={() => setImageModalVerse(true)}
                  />
                ) : (
                  <>
                    <a
                      href="#"
                      onClick={() =>
                        handleDownload(
                          client?.photo_card_verse,
                          client?.photo_card_verse_url
                        )
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'baseline'
                      }}
                    >
                      <p
                        className="fw-bolder fs-6"
                        style={{
                          maxWidth: '50%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          margin: '0px 20px 0px 20px'
                        }}
                      >
                        {client?.photo_card_verse}
                      </p>
                      <span
                        className="fa fa-download"
                        style={{ marginRight: '5px', fontSize: '14px' }}
                      ></span>
                    </a>
                  </>
                )}
              </div>
              <div
                className="my-10 col"
                style={{
                  minWidth: '350px'
                }}
              >
                {client?.photo_document_1_type?.includes('media') ? (
                  <img
                    style={{
                      width: 350,
                      objectFit: 'cover',
                      height: 200
                    }}
                    alt=""
                    className="img-bordered"
                    src={client?.photo_document_1_url || ''}
                    onClick={() => setImageModalDocument1(true)}
                  />
                ) : (
                  <>
                    <a
                      href="#"
                      onClick={() =>
                        handleDownload(
                          client?.photo_document_1,
                          client?.photo_document_1_url
                        )
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'baseline'
                      }}
                    >
                      <p
                        className="fw-bolder fs-6"
                        style={{
                          maxWidth: '50%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          margin: '0px 20px 0px 20px'
                        }}
                      >
                        {client?.photo_document_1}
                      </p>
                      <span
                        className="fa fa-download"
                        style={{ marginRight: '5px', fontSize: '14px' }}
                      ></span>
                    </a>
                  </>
                )}
              </div>
              <div
                className="my-10 col"
                style={{
                  minWidth: '350px'
                }}
              >
                {client?.photo_document_2_type?.includes('media') ? (
                  <img
                    style={{
                      width: 350,
                      objectFit: 'cover',
                      height: 200
                    }}
                    alt=""
                    className="img-bordered"
                    src={client?.photo_document_2_url || ''}
                    onClick={() => setImageModalDocument2(true)}
                  />
                ) : (
                  <>
                    <a
                      href="#"
                      onClick={() =>
                        handleDownload(
                          client?.photo_document_2,
                          client?.photo_document_2_url
                        )
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'baseline'
                      }}
                    >
                      <p
                        className="fw-bolder fs-6"
                        style={{
                          maxWidth: '50%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          margin: '0px 20px 0px 20px'
                        }}
                      >
                        {client?.photo_document_2}
                      </p>
                      <span
                        className="fa fa-download"
                        style={{ marginRight: '5px', fontSize: '14px' }}
                      ></span>
                    </a>
                  </>
                )}
              </div>
              <div
                className="my-10 col"
                style={{
                  minWidth: '350px'
                }}
              >
                {client?.photo_selfie_document_type?.includes('media') ? (
                  <img
                    style={{
                      width: 350,
                      objectFit: 'cover',
                      height: 200
                    }}
                    alt=""
                    className="img-bordered"
                    src={client?.photo_selfie_document_url || ''}
                    onClick={() => setImageModalDocumentSelfie(true)}
                  />
                ) : (
                  <>
                    <a
                      href="#"
                      onClick={() =>
                        handleDownload(
                          client?.photo_selfie_document,
                          client?.photo_selfie_document_url
                        )
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'baseline'
                      }}
                    >
                      <p
                        className="fw-bolder fs-6"
                        style={{
                          maxWidth: '50%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          margin: '0px 20px 0px 20px'
                        }}
                      >
                        {client?.photo_selfie_document}
                      </p>
                      <span
                        className="fa fa-download"
                        style={{ marginRight: '5px', fontSize: '14px' }}
                      ></span>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body p-9">
            <h1>Observações</h1>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <p className="fw-bolder fs-6 text-gray-800">
                    {client?.observation}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imageModalFront && (
        <ModalImage onClick={() => setImageModalFront(false)}>
          <img
            style={{
              objectFit: 'cover'
            }}
            alt=""
            className="img-bordered"
            src={client?.photo_card_front_url || ''}
          />
        </ModalImage>
      )}
      {imageModalVerse && (
        <ModalImage onClick={() => setImageModalVerse(false)}>
          <img
            style={{
              objectFit: 'cover'
            }}
            alt=""
            className="img-bordered"
            src={client?.photo_card_verse_url || ''}
          />
        </ModalImage>
      )}
      {imageModalDocument1 && (
        <ModalImage onClick={() => setImageModalDocument1(false)}>
          <img
            style={{
              objectFit: 'cover'
            }}
            alt=""
            className="img-bordered"
            src={client?.photo_document_1_url || ''}
          />
        </ModalImage>
      )}
      {imageModalDocument2 && (
        <ModalImage onClick={() => setImageModalDocument2(false)}>
          <img
            style={{
              objectFit: 'cover'
            }}
            alt=""
            className="img-bordered"
            src={client?.photo_document_2_url || ''}
          />
        </ModalImage>
      )}
      {imageModalDocumentSelfie && (
        <ModalImage onClick={() => setImageModalDocumentSelfie(false)}>
          <img
            style={{
              objectFit: 'cover'
            }}
            alt=""
            className="img-bordered"
            src={client?.photo_selfie_document_url || ''}
          />
        </ModalImage>
      )}
      {imageModalProofPayment && (
        <ModalImage onClick={() => setImageModalProofPayment(false)}>
          <img
            style={{
              objectFit: 'cover'
            }}
            alt=""
            className="img-bordered"
            src={client?.payment_proof_url || ''}
          />
        </ModalImage>
      )}
    </div>
  )
}

export default ClientView
