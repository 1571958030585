import React from 'react'
import { useAuth } from '../../../../hooks/auth'
import { Container } from './styles'

interface Menu {
  id: number
  parent_id?: number
  method?: string
  name: string
  url?: string
  permission: boolean
  children?: Menu[]
  icon?: string
}

export function AsideMenuMain() {
  const { menus } = useAuth()
  const createMenu = (arrayMenus: Menu[], submenu = false) =>
    !!arrayMenus?.length &&
    arrayMenus
      .filter(
        menu =>
          (!menu.method && menu.children) ||
          (menu.method && menu.permission === true)
      )
      .map(menu => {
        let showMenu = true
        if (menu.method === null && (menu.children || menu.url)) {
          if (menu.children) {
            const subMenus = menu.children.filter(
              sub => sub.permission === true
            )
            showMenu = subMenus.length > 0
          }
          showMenu = true
        }
        if (showMenu && menu.permission) {
          const menuName = !submenu
            ? menu.name
            : menu.name[0].toUpperCase() +
              menu.name.substring(1, menu.name.length).toLowerCase()
          return menu.children.length ? (
            <div
              className={
                !submenu
                  ? 'menu-item menu-accordion'
                  : menu.children.length
                  ? 'menu-item menu-accordion'
                  : 'menu-item menu-link without-sub'
              }
              data-kt-menu-trigger="click"
              key={menu.id}
            >
              <span
                className={`${
                  menu.children.length
                    ? 'menu-link'
                    : submenu
                    ? 'd-flex'
                    : 'menu-link'
                }`}
              >
                <span className="menu-icon" style={{ width: 'unset' }}>
                  <span className={menu.icon}></span>
                </span>
                {submenu && (
                  <span className="menu-bullet">
                    <span className="bullet bullet-dot"></span>
                  </span>
                )}
                <span className="menu-title">{menuName}</span>
                {menu.children.length ? (
                  <span className="menu-arrow"></span>
                ) : (
                  <div></div>
                )}
              </span>
              {menu.children && (
                <div
                  className="menu-sub menu-sub-accordion"
                  data-kt-menu-dismiss="true"
                >
                  {createMenu(menu.children, true)}
                </div>
              )}
            </div>
          ) : (
            <div className="menu-item" key={menu.id}>
              <a href={menu.url ? menu.url : '#!'} className="menu-link">
                {!submenu && (
                  <span className="menu-icon" style={{ width: 'unset' }}>
                    <span className={menu.icon}></span>
                  </span>
                )}
                {submenu && (
                  <span className="menu-bullet">
                    <span className="bullet bullet-dot"></span>
                  </span>
                )}
                <span className="menu-title">{menuName}</span>
                {menu.children.length ? (
                  <span className="menu-arrow"></span>
                ) : (
                  <div></div>
                )}
              </a>
            </div>
          )
        }
        return ''
      })
  return <Container>{createMenu(menus)}</Container>
}
