import React from 'react'
import { CustomSwitch } from '../../components/CustomSwitch'
import { Route } from 'react-router-dom'
import Simulation from '../../pages/Simulation'
import Client from '../../pages/Simulation/Client'
import Card from '../../pages/Simulation/Card'
import Contract from '../../pages/Simulation/Contract'

export const SimulationsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/simulation" exact component={Simulation} />
    <Route path="/simulation/client" component={Client} exact />
    <Route path="/simulation/card" component={Card} exact />
    <Route path="/simulation/contract" component={Contract} exact />
  </CustomSwitch>
)
