import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { FormClient } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../hooks/toast'

const ClientUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clientItem, setClientItem] = useState<{
    id: string
    full_name?: string
    cpf_cnpj?: string
    rg_partner?: string
    type?: 'PF' | 'PJ'
    street?: string
    district?: string
    city?: string
    state?: string
    phone?: string
    email?: string
    zip_code?: string
    card_number?: string
    card_flag?: string
    expiration_date?: string
    desired_value?: string
    number_installments?: number
    photo_card_front?: string
    photo_card_verse?: string
    photo_selfie_document?: string
    photo_document_1?: string
    photo_document_2?: string
    observation?: string
    status?: 'Novo' | 'Em Andamento' | 'Cancelado' | 'Finalizado'
    site?: string
    instagram?: string
    whatsapp?: string
    operation_type?: 'Micro Crédito' | 'Pagamento de Conta'
  }>()

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setClientItem(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o cliente',
          description:
            'Houve um error ao carregar o cliente, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormClient
        typeForm="update"
        initialValues={{
          idUpdate: Number(clientItem?.id),
          ...clientItem
        }}
      />
    </Container>
  )
}

export default ClientUpdate
