import React, { useCallback, useEffect, useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import { StringParam, useQueryParam } from 'use-query-params'
import Form, { Select } from '../../../../components/Form'
import { Date as DatePicker } from '../../../../components/Form/date'

const ClientList: React.FC = () => {
  const [status] = useQueryParam('status', StringParam)
  const [date] = useQueryParam('date', StringParam)
  const [endDateQuery] = useQueryParam('endDate', StringParam)
  const [clearFilter, setClearFilter] = useState<any>()

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  function capitalize(word: string) {
    if (!word && !word?.length) return
    return word
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, letter =>
        letter.replace(/^\w/, firstLetter => firstLetter.toUpperCase())
      )
  }
  const [searchParameters, setSearchParameters] = useState<any[]>([
    { status: capitalize(status), start_date: date, end_date: endDateQuery }
  ])
  const handleClearFilter = useCallback(() => {
    setClearFilter({
      status: '',
      start_date: '',
      end_date: ''
    })
    setStartDate(undefined)
    setEndDate(undefined)
    setSearchParameters([])
  }, [])
  const handleSetFilter = useCallback(data => {
    if (data.status) {
      data.status = capitalize(data.status)
    }
    setSearchParameters([data])
  }, [])
  useEffect(() => {
    setSearchParameters([
      { status: capitalize(status), start_date: date, end_date: endDateQuery }
    ])
  }, [date, status, endDateQuery])
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <Form onSubmit={handleSetFilter} defaultValues={clearFilter}>
        <div className="form">
          <div className="row mb-5">
            <DatePicker
              className="col-md-3"
              name="start_date"
              label="Início"
              selected={startDate}
              onChange={date => setStartDate(date)}
              controlled
            />
            <DatePicker
              label="Término"
              className="col-md-3"
              name="end_date"
              selected={endDate}
              onChange={date => setEndDate(date)}
              controlled
            />
            <Select
              className="col-md-3"
              label="Tipo de Operação"
              name="operation_type"
              options={[
                {
                  name: 'Micro Crédito',
                  value: 'Micro Crédito'
                },
                {
                  name: 'Pagamento de Conta',
                  value: 'Pagamento de Conta'
                }
              ]}
              blank
              defaultValue={''}
              rules={{ required: true }}
            />
            <Select
              className="col-md-3"
              name="status"
              label="Status"
              options={[
                {
                  name: 'Novo',
                  value: 'Novo'
                },
                {
                  name: 'Em Andamento',
                  value: 'Em Andamento'
                },
                {
                  name: 'Cancelado',
                  value: 'Cancelado'
                },
                {
                  name: 'Finalizado',
                  value: 'Finalizado'
                }
              ]}
              blank
              defaultValue=""
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="col-md-2 d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-light-primary"
              onClick={handleClearFilter}
            >
              Limpar
            </button>
          </div>
          <div className="col-md-1 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              Filtrar
            </button>
          </div>
        </div>
      </Form>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        totalColumn="created_at"
        format={{ orderBy: 'id' }}
        notHasChildren
        headers={headers}
        searchParameters={[{ ...searchParameters[0], hasCustom: true }]}
      />
    </Container>
  )
}

export default ClientList
