import React, { useCallback, useEffect, useRef, useState } from 'react'
import logoDark from '../../assets/media/logos/logo-b2b.png'
import Form, { Input } from '../../components/Form'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'
import Modal from '../../components/Modal'

interface SingInFormData {
  username: string
  password: string
}

const SignIn: React.FC = () => {
  const refModal = useRef(null)
  const { signIn, backupHistory, setHistory } = useAuth()
  const { addToast } = useToast()
  const [modalEdit, setModalEdit] = useState(false)

  const onSubmit = useCallback(
    async (data: SingInFormData) => {
      try {
        await signIn({
          username: data.username,
          password: data.password
        })
        const a = document.createElement('a')
        a.href = backupHistory || '/dashboard'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setHistory('')
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Confira seus dados de acesso.'
        })
      }
    },
    [signIn, backupHistory, setHistory, addToast]
  )

  const handleClickOnClose = () => {
    setModalEdit(false)
  }

  const handleForgotPassword = useCallback(
    async ({ username }) => {
      handleClickOnClose()
      try {
        await api.post('/password/forgot', {
          username
        })
        addToast({
          type: 'success',
          title: 'Email enviado com sucesso',
          description: 'Email de renovação de senha enviado.'
        })
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao enviar email',
          description: 'Erro na tentativa de enviar o email.'
        })
      }
    },
    [addToast]
  )

  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" className="mb-12">
            <img
              alt="Logo"
              src={logoDark}
              className="h-150px w-400px"
              style={{ objectFit: 'cover' }}
            />
          </a>
          <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
            <Form className="form w-100" onSubmit={onSubmit}>
              <div className="fv-row">
                <Input
                  autoComplete="off"
                  label="Usuário"
                  name="username"
                  rules={{ required: true }}
                />
              </div>

              <div className="fv-row mb-10">
                <Input
                  className=" placeholder-no-fix"
                  type="password"
                  autoComplete="off"
                  label="Senha"
                  name="password"
                  rules={{ required: true }}
                />
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-lg btn-primary w-100 mb-5"
                >
                  <span className="indicator-label">Continuar</span>
                </button>
                <a href="#" onClick={() => setModalEdit(true)}>
                  Esqueceu a senha
                </a>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={modalEdit}
        pageTitle={'Esqueceu a senha'}
        Children={
          <Form classname="form w-100" onSubmit={handleForgotPassword}>
            <div className="fv-row">
              <Input
                autoComplete="off"
                placeholder="Usuário"
                label="Usuário"
                name="username"
                rules={{ required: true }}
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
              >
                <span className="indicator-label">Continuar</span>
              </button>
            </div>
          </Form>
        }
      />
    </>
  )
}

export default SignIn
