import React, { useCallback, useState } from 'react'
import logo from '../../../assets/media/logos/logo-b2b.png'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  WrapperContent
} from './styles'
import Form, { Input, Select } from '../../../components/Form'
import { useLoading } from '../../../hooks/loading'
import { useToast } from '../../../hooks/toast'
import { cnpjMask, cpfMask, phoneMask, zipCodeMask } from '../../../utlis/mask'
import { Loading } from '../../../components/Loading'
import { useHistory, useLocation } from 'react-router-dom'
import jsonp from 'jsonp'
import { Stepper } from '../../../components/Stepper'
import axios from 'axios'

type ClientData = {
  id?: string
  full_name?: string
  cpf_cnpj?: string
  rg_partner?: string
  type?: 'PF' | 'PJ'
  street?: string
  district?: string
  city?: string
  state?: string
  phone?: string
  email?: string
  zip_code?: string
  card_number?: string
  card_flag?: string
  expiration_date?: string
  desired_value?: string
  number_installments?: number
  photo_card_front?: string
  photo_card_verse?: string
  photo_selfie_document?: string
  photo_document_1?: string
  photo_document_2?: string
  observation?: string
  status?: 'Novo' | 'Em Andamento' | 'Cancelado' | 'Finalizado'
  site?: string
  instagram?: string
  whatsapp?: string
  full_name_partner?: string
  cpf_partner?: string
  number?: string
  complement?: string
}

type Company = {
  company_social_name: string
  company_name: string
  cnpj: string
  number: string
  complement: string
  cnpjSearch: string
}
type Address = {
  street: string
  district: string
  city: string
  state: string
  ibge: string
}

export const Client: React.FC = () => {
  const { activeLoading, disableLoading, loading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const location = useLocation<any>()
  const [company, setCompany] = useState<Company>()
  const [address, setAddress] = useState<Address>()
  const [documnetNumber, setDocumentNumber] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [whatsapp, setWhatsapp] = useState<string>('')
  const [typePerson, setTypePerson] = useState<string>('')
  const [partnerCPF, setpartnerCPF] = useState<string>('')
  const typePersonLabel: any = {
    PJ: 'CNPJ',
    PF: 'CPF'
  }
  const onSubmit = useCallback(
    (data: ClientData) => {
      history.push({
        pathname: '/simulation/card',
        state: {
          ...location.state,
          ...data,
          steps: {
            1: 'completed',
            2: 'completed',
            3: 'current',
            4: ''
          }
        }
      })
    },
    [history, location.state]
  )

  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      setZipCode(zipCodeMask(zipCodeData))
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${zipCodeSearch}/json`,
            {
              timeout: 10000
            }
          )
          const { bairro, ibge, localidade, logradouro, uf } = response.data
          setAddress({
            city: localidade,
            district: bairro,
            state: uf,
            street: logradouro,
            ibge
          })
        } catch (error) {
          addToast({
            title: 'Erro ao carregar os dados para o cep',
            description:
              'Houve um erro ao carregar os dados para o cep, digite os dados manualemnte!',
            type: 'error'
          })
        }
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading]
  )
  const getDataCnpj = useCallback(
    async (cnpjData: string) => {
      setDocumentNumber(cnpjMask(cnpjData))
      const cnpjSearch = cnpjData?.replaceAll(/[.\-/]/g, '')
      if (cnpjSearch.length === 14) {
        activeLoading()
        jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${cnpjSearch}`,
          {
            timeout: 10000
          },
          (error: any, data: any) => {
            if (error) {
              addToast({
                title: 'Erro ao carregar os dados para o cnpj',
                description:
                  'Houve um erro ao carregar os dados para o cnpj, digite os dados manualemnte!',
                type: 'error'
              })
            } else {
              setCompany({
                cnpjSearch: data,
                company_social_name: data.fantasia,
                company_name: data.nome,
                cnpj: cnpjSearch,
                complement: data.complemento,
                number: data.numero
              })
              getDataCep(data.cep)
            }
            disableLoading()
          }
        )
      }
    },
    [activeLoading, addToast, disableLoading, getDataCep]
  )

  const handleDocumentNumber = useCallback(
    (value: string) => {
      if (typePerson === 'PF') {
        setDocumentNumber(cpfMask(value))
        return
      }
      if (typePerson === 'PJ') {
        getDataCnpj(value)
      }
    },
    [getDataCnpj, typePerson]
  )

  return (
    <div>
      <Loading isActive={loading} />
      <div className="row gy-5 g-xl-8">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="h-55px w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="row gy-5 g-xl-8 w-100">
          <div className="card p-10">
            <Stepper
              steps={[
                {
                  status: location?.state.steps['1'],
                  step: '1',
                  title: 'Simulação'
                },
                {
                  status: location?.state.steps['2'],
                  step: '2',
                  title: 'Dados Pessoais'
                },
                {
                  status: location?.state.steps['3'],
                  step: '3',
                  title: 'Dados do Cartão'
                },
                {
                  status: location?.state.steps['4'],
                  step: '4',
                  title: 'Termos e Condições'
                }
              ]}
            />
            <h1>Dados Pessoais</h1>
            <div className="form">
              <Form
                className="form"
                onSubmit={onSubmit}
                defaultValues={{
                  ...address,
                  ...company
                }}
              >
                <div className="row mb-5">
                  <Select
                    className="col-md-3"
                    name="type"
                    label="Tipo"
                    options={[
                      {
                        name: 'Pessoa Física',
                        value: 'PF'
                      },
                      {
                        name: 'Pessoa Jurídica',
                        value: 'PJ'
                      }
                    ]}
                    blank
                    defaultValue=""
                    rules={{ required: true }}
                    onChange={event => setTypePerson(event.target.value)}
                  />
                  <Input
                    name="cpf_cnpj"
                    className="col-md-3"
                    label={
                      typePerson ? typePersonLabel[typePerson] : 'CNPJ/CPF'
                    }
                    rules={{ required: true }}
                    value={documnetNumber}
                    readOnly={!typePerson.length}
                    onChange={event => handleDocumentNumber(event.target.value)}
                  />
                  <Input
                    name="full_name"
                    className="col-md-6"
                    label="Nome Completo"
                    rules={{ required: true }}
                  />
                </div>
                {typePerson === 'PJ' && (
                  <div className="row mb-5">
                    <Input
                      name="rg_partner"
                      className="col-md-3"
                      label="RG Sócio"
                      rules={{ required: typePerson === 'PJ' }}
                    />
                    <Input
                      name="cpf_partner"
                      className="col-md-3"
                      label="CPF Sócio"
                      rules={{ required: typePerson === 'PJ' }}
                      onChange={event =>
                        setpartnerCPF(cpfMask(event.target.value))
                      }
                      value={partnerCPF}
                    />
                    <Input
                      name="full_name_partner"
                      className="col-md-6"
                      label="Nome Completo Sócio"
                      rules={{ required: typePerson === 'PJ' }}
                    />
                  </div>
                )}
                <div className="row mb-5">
                  <Input
                    name="zip_code"
                    className="col-md-3"
                    label="CEP"
                    rules={{ required: true }}
                    value={zipCode}
                    onChange={event => getDataCep(event.target.value)}
                    maxLength={9}
                  />
                  <Input
                    name="street"
                    className=" col-md-6"
                    label="Logradouro"
                    value={address?.street}
                    rules={{ required: true }}
                  />
                  <Input
                    name="number"
                    className=" col-md-3"
                    label="Número"
                    value={company?.number}
                    rules={{ required: !company?.number }}
                  />
                </div>
                <div className="row mb-5">
                  <Input
                    name="complement"
                    className=" col-md-3"
                    label="Complemento"
                    value={company?.complement}
                    rules={{ required: !company?.complement }}
                  />
                  <Input
                    name="district"
                    value={address?.district}
                    className="col-md-3"
                    label="Bairro"
                    rules={{ required: true }}
                  />
                  <Input
                    name="city"
                    className="col-md-3"
                    label="Cidade"
                    value={address?.city}
                    rules={{ required: true }}
                  />
                  <Input
                    name="state"
                    value={address?.state}
                    className="col-md-3"
                    label="UF"
                    rules={{ required: true }}
                  />
                </div>
                <div className="row mb-5">
                  <Input
                    name="phone"
                    className="col-md-3"
                    label="Telefone"
                    value={phone}
                    onChange={event => setPhone(phoneMask(event.target.value))}
                    maxLength={15}
                    rules={{ required: true }}
                  />
                  <Input
                    name="email"
                    className="col-md-3"
                    label="E-mail"
                    rules={{ required: true }}
                  />
                  <Input name="site" className="col-md-3" label="Site" />
                  <Input
                    name="instagram"
                    className="col-md-3"
                    label="Instagram"
                  />
                  <Input
                    name="whatsapp"
                    className="col-md-3"
                    label="WhatsApp"
                    value={whatsapp}
                    onChange={event =>
                      setWhatsapp(phoneMask(event.target.value))
                    }
                    maxLength={15}
                  />
                </div>
                <div className="row d-flex justify-content-end py-6 px-9">
                  <div className="col-md-3 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Próximo
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </ContainerWrapperContent>
    </div>
  )
}

export default Client
