import styled from 'styled-components'

export const WrapperTablePortlet = styled.div`
  div.switchButton {
    border-radius: unset !important;
  }
`
export const ModalImage = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 200;
  padding: 10% 25%;
  img {
    width: auto;
    height: 100%;
  }
`
