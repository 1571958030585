import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import {
  cardMask,
  cnpjMask,
  convertValueMaskInNumberWithTwoZero,
  cpfMask,
  genericMaskWithTwoZero,
  genericMaskWithTwoZeroWithPoint,
  phoneMask,
  zipCodeMask
} from '../../../../../utlis/mask'
import jsonp from 'jsonp'
import { IMaskInput, IMask } from 'react-imask'
import moment from 'moment'
import currency from 'currency.js'
import { Alert } from '../../../../../components/Alert'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type ClientData = {
  full_name?: string
  cpf_cnpj?: string
  rg_partner?: string
  type?: 'PF' | 'PJ'
  street?: string
  district?: string
  city?: string
  state?: string
  phone?: string
  email?: string
  zip_code?: string
  card_number?: string
  card_flag?: string
  expiration_date?: string
  desired_value?: string
  number_installments?: number
  photo_card_front?: string
  photo_card_verse?: string
  photo_selfie_document?: string
  photo_document_1?: string
  photo_document_2?: string
  observation?: string
  status?: 'Novo' | 'Em Andamento' | 'Cancelado' | 'Finalizado'
  site?: string
  instagram?: string
  whatsapp?: string
  full_name_partner?: string
  cpf_partner?: string
  number?: string
  complement?: string
  operation_total?: string
  fees?: string
  installment_value?: string
  amount_receivable?: string
  sendMail?: boolean
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ClientData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

type Company = {
  company_social_name: string
  company_name: string
  cnpj: string
  number: string
  complement: string
  cnpjSearch: string
}
type Address = {
  street: string
  district: string
  city: string
  state: string
  ibge: string
}

type OperationIndexData = {
  id?: string
  title?: string
  index?: string
  number_installments?: string
  requested_amount?: string
}

export const FormClient = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const [defaultValues, setDefaultValues] = useState<ClientData>(
    {} as ClientData
  )
  const [company, setCompany] = useState<Company>()
  const [address, setAddress] = useState<Address>()
  const [documnetNumber, setDocumentNumber] = useState<string>()
  const [zipCode, setZipCode] = useState<string>()
  const [phone, setPhone] = useState<string>()
  const [whatsapp, setWhatsapp] = useState<string>()
  const [photos, setPhotos] = useState<File[]>([])
  const [typePerson, setTypePerson] = useState<string>()
  const [requestedAmount, setRequestedAmount] = useState<string>('0,00')
  const [operationTotal, setOperationTotal] = useState('0,00')
  const [fees, setFees] = useState('0,00')
  const [installmentValue, setInstallmentValue] = useState('0,00')
  const [amountReceivable, setAmountReceivable] = useState('0,00')
  const [expirateDate, setExpirateDate] = useState<Date>()
  const [partnerCPF, setpartnerCPF] = useState<string>('')
  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardFlag, setCardFlag] = useState<string>('')
  const [numberInstallments, setNumberInstallments] = useState<string>()
  const [sendMail, setSendMail] = useState(false)
  const [dataForm, setDataForm] = useState<ClientData>()
  const typePersonLabel: any = {
    PJ: 'CNPJ',
    PF: 'CPF'
  }
  const cards: any = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})/,
    Mastercard: /^5[1-5][0-9]{14}/,
    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    Amex: /^3[47][0-9]{13}/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    Jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    Aura: /^(5078\d{2})(\d{2})(\d{11})$/
  }
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  useEffect(() => {
    if (initialValues?.cpf_cnpj && !defaultValues.cpf_cnpj) {
      setDefaultValues({
        ...initialValues,
        cpf_cnpj:
          initialValues.type === 'PF'
            ? cpfMask(initialValues?.cpf_cnpj || '')
            : cnpjMask(initialValues?.cpf_cnpj || ''),
        cpf_partner: cpfMask(initialValues?.cpf_partner || ''),
        phone: phoneMask(initialValues?.phone || ''),
        desired_value: genericMaskWithTwoZeroWithPoint(
          initialValues.desired_value || '0,00'
        ),
        operation_total: genericMaskWithTwoZeroWithPoint(
          initialValues.operation_total || '0,00'
        ),
        fees: genericMaskWithTwoZeroWithPoint(initialValues.fees || '0,00'),
        installment_value: genericMaskWithTwoZeroWithPoint(
          initialValues.installment_value || '0,00'
        ),
        amount_receivable: genericMaskWithTwoZeroWithPoint(
          initialValues.amount_receivable || '0,00'
        ),
        card_flag: initialValues.card_flag,
        card_number: initialValues.card_number
      })
      setTypePerson(initialValues?.type)
      setpartnerCPF(cpfMask(initialValues?.cpf_partner || ''))
      setRequestedAmount(
        genericMaskWithTwoZeroWithPoint(initialValues.desired_value || '0,00')
      )
      setOperationTotal(
        genericMaskWithTwoZeroWithPoint(initialValues.operation_total || '0,00')
      )
      setFees(genericMaskWithTwoZeroWithPoint(initialValues.fees || '0,00'))
      setInstallmentValue(
        genericMaskWithTwoZeroWithPoint(
          initialValues.installment_value || '0,00'
        )
      )
      setAmountReceivable(
        genericMaskWithTwoZeroWithPoint(
          initialValues.amount_receivable || '0,00'
        )
      )
      setNumberInstallments(`${initialValues.number_installments}`)
      setCardFlag(initialValues.card_flag)
      setCardNumber(initialValues.card_number)
    }
  }, [defaultValues, initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      setZipCode(zipCodeMask(zipCodeData))
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        try {
          const response = await api.get(
            `https://viacep.com.br/ws/${zipCodeSearch}/json`,
            {
              timeout: 10000
            }
          )
          const { bairro, ibge, localidade, logradouro, uf } = response.data
          setAddress({
            city: localidade,
            district: bairro,
            state: uf,
            street: logradouro,
            ibge
          })
        } catch (error) {
          addToast({
            title: 'Erro ao carregar os dados para o cep',
            description:
              'Houve um erro ao carregar os dados para o cep, digite os dados manualemnte!',
            type: 'error'
          })
        }
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading]
  )
  const getDataCnpj = useCallback(
    async (cnpjData: string) => {
      setDocumentNumber(cnpjMask(cnpjData))
      const cnpjSearch = cnpjData?.replaceAll(/[.\-/]/g, '')
      if (cnpjSearch.length === 14) {
        activeLoading()
        jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${cnpjSearch}`,
          {
            timeout: 10000
          },
          (error: any, data: any) => {
            if (error) {
              addToast({
                title: 'Erro ao carregar os dados para o cnpj',
                description:
                  'Houve um erro ao carregar os dados para o cnpj, digite os dados manualemnte!',
                type: 'error'
              })
            } else {
              setCompany({
                cnpjSearch: data,
                company_social_name: data.fantasia,
                company_name: data.nome,
                cnpj: cnpjSearch,
                complement: data.complemento,
                number: data.numero
              })
              getDataCep(data.cep)
            }
            disableLoading()
          }
        )
      }
    },
    [activeLoading, addToast, disableLoading, getDataCep]
  )
  const removeDuplicated = (data: any[]) => {
    const unique: any[] = []
    const filteredItems = data.filter(item => {
      const isDuplicated = unique.includes(item.name)
      if (!isDuplicated) {
        unique.push(item.name)
        return true
      }
      return false
    })
    return filteredItems
  }
  const onSubmitForm = useCallback(
    async (data: ClientData) => {
      if (
        data.status !== 'Novo' &&
        data.status !== initialValues?.status &&
        !sendMail
      ) {
        setSendMail(true)
        setDataForm(data)
        setIsActiveAlert({
          ...alert,
          isActive: true
        })
        return
      }
      if (sendMail) {
        setIsActiveAlert({
          ...alert,
          isActive: false
        })
      }
      data = {
        ...data,
        ...address,
        ...company,
        zip_code: zipCode,
        operation_total: operationTotal,
        fees: fees,
        installment_value: installmentValue,
        amount_receivable: amountReceivable,
        sendMail
      }
      const id = initialValues?.idUpdate
      data.desired_value = genericMaskWithTwoZero(
        data.desired_value
      ).replaceAll(',', '.')
      data.operation_total = genericMaskWithTwoZero(
        data.operation_total
      ).replaceAll(',', '.')
      data.fees = genericMaskWithTwoZero(data.fees).replaceAll(',', '.')
      data.installment_value = genericMaskWithTwoZero(
        data.installment_value
      ).replaceAll(',', '.')
      data.amount_receivable = genericMaskWithTwoZero(
        data.amount_receivable
      ).replaceAll(',', '.')

      if (typeof data.expiration_date === 'object') {
        data.expiration_date = Intl.DateTimeFormat('en-US', {
          month: '2-digit',
          year: '2-digit'
        }).format(new Date(expirateDate))
      }
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value === 'object' && value) {
          formData.append(key, value[0])
          return
        }
        if (value) {
          formData.append(key, String(value))
        }
      })
      try {
        if (typeForm === 'create') {
          if (isOpenInModal) {
            const { handleOnClose } = isOpenInModal
            activeLoading()
            try {
              await api.post(apiCreate(), formData)
              handleOnClose()
              disableLoading()
              updateDataTable()
              addToast({
                type: 'success',
                title: 'Registro criado',
                description: 'Registro criado com sucesso'
              })
            } catch (error) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
              })
              handleOnClose()
              disableLoading()
              updateDataTable()
            }
          } else {
            try {
              activeLoading()
              await api.post(apiCreate(), formData)
              disableLoading()
              updateDataTable()
              addToast({
                type: 'success',
                title: 'Registro criado',
                description: 'Registro criado com sucesso'
              })
              history.push(nameActions.read.to)
            } catch (error) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description:
                  'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
              })
              disableLoading()
              updateDataTable()
            }
          }
        } else {
          if (isOpenInModal) {
            const { handleOnClose } = isOpenInModal

            try {
              activeLoading()
              await api.put(apiUpdate(String(id)), formData)
              updateDataTable()
              disableLoading()
              handleOnClose()
              addToast({
                type: 'success',
                title: 'Registro atualizado',
                description: 'Registro alterado com sucesso'
              })
            } catch (error) {
              disableLoading()
              handleOnClose()
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description:
                  'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
              })
            }
          } else {
            try {
              activeLoading()
              await api.put(apiUpdate(String(id)), formData)
              updateDataTable()
              disableLoading()
              history.push(nameActions.read.to)
              addToast({
                type: 'success',
                title: 'Registro atualizado',
                description: 'Registro alterado com sucesso'
              })
            } catch (error) {
              history.push(nameActions.read.to)
              addToast({
                type: 'error',
                title: 'Erro ao atualizar o registro',
                description:
                  'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
              })
            }
          }
        }
        disableLoading()
      } catch (err) {
        if (typeForm === 'create') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          if (isOpenInModal) isOpenInModal.handleOnClose()
        }
      }
    },
    [
      activeLoading,
      addToast,
      address,
      alert,
      amountReceivable,
      company,
      disableLoading,
      expirateDate,
      fees,
      history,
      initialValues,
      installmentValue,
      isOpenInModal,
      operationTotal,
      sendMail,
      typeForm,
      updateDataTable,
      zipCode
    ]
  )

  function onChangeFileHandler(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return
    const file = event.target.files[0]
    const copyFilesUploads = [...photos, file]
    const filterFilesUploads: File[] = removeDuplicated(copyFilesUploads)
    setPhotos(filterFilesUploads)
  }

  const handleDocumentNumber = useCallback(
    (value: string) => {
      if (typePerson === 'PF') {
        setDocumentNumber(cpfMask(value))
        return
      }
      if (typePerson === 'PJ') {
        getDataCnpj(value)
      }
    },
    [getDataCnpj, typePerson]
  )
  const handleFlagCard = useCallback((value: string) => {
    setCardNumber(cardMask(value))
    const cardNumber = value.replace(/[^0-9]+/g, '')
    for (const flag in cards) {
      if (cards[flag].test(cardNumber)) {
        setCardFlag(flag)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSimulation = useCallback(
    async (number_installments?: string) => {
      if (!requestedAmount || requestedAmount === '0,00') {
        return
      }
      const copyNumberInstallments = number_installments || numberInstallments
      if (!copyNumberInstallments) {
        return
      }

      setNumberInstallments(copyNumberInstallments)
      activeLoading()
      try {
        const { data } = await api.get<OperationIndexData>(
          `/financial/public/operationIndexes/findByNumberInstallment/${copyNumberInstallments}`
        )
        const index = Number(data.index.replaceAll(',', '.'))
        const numberRequestedAmount =
          convertValueMaskInNumberWithTwoZero(requestedAmount)
        const totalValue = numberRequestedAmount * index
        setOperationTotal(BRL(totalValue).format())
        setFees(BRL(totalValue).subtract(numberRequestedAmount).format())
        setInstallmentValue(
          BRL(totalValue).divide(Number(copyNumberInstallments)).format()
        )
        setAmountReceivable(BRL(requestedAmount).format())
        disableLoading()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar indice',
          description:
            'Ocorreu um erro ao buscar o indice, por favor, tente novamente.'
        })
        disableLoading()
      }
    },
    [
      activeLoading,
      addToast,
      disableLoading,
      numberInstallments,
      requestedAmount
    ]
  )
  useEffect(() => {
    let timer: NodeJS.Timeout
    if (requestedAmount) {
      timer = setTimeout(() => {
        handleSimulation()
      }, 500)
    } else {
      setRequestedAmount('0,00')
      setOperationTotal('0,00')
      setFees('0,00')
      setInstallmentValue('0,00')
      setAmountReceivable('0,00')
    }

    return () => clearTimeout(timer)
  }, [handleSimulation, requestedAmount])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
  }
  const handlerClickButtonConfirmAlert = useCallback(async () => {
    await onSubmitForm(dataForm)
  }, [dataForm, onSubmitForm])
  return (
    <>
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <>
          <div className="card mb-5 mb-xl-10">
            <FormContainer className="form-body">
              <div className="row mb-5">
                <Select
                  className="col-md-3"
                  name="type"
                  label="Tipo"
                  options={[
                    {
                      name: 'Pessoa Física',
                      value: 'PF'
                    },
                    {
                      name: 'Pessoa Jurídica',
                      value: 'PJ'
                    }
                  ]}
                  blank
                  defaultValue=""
                  rules={{ required: true }}
                  onChange={event => setTypePerson(event.target.value)}
                />
                <Input
                  name="cpf_cnpj"
                  className="col-md-3"
                  label={typePerson ? typePersonLabel[typePerson] : 'CNPJ/CPF'}
                  rules={{ required: true }}
                  value={documnetNumber}
                  readOnly={!typePerson?.length}
                  onChange={event => handleDocumentNumber(event.target.value)}
                />
                <Input
                  name="full_name"
                  className="col-md-6"
                  label="Nome Completo"
                  rules={{ required: true }}
                />
              </div>
              <div className="row mb-5">
                {typePerson === 'PJ' && (
                  <>
                    <Input
                      name="rg_partner"
                      className="col-md-3"
                      label="RG Sócio"
                      rules={{ required: typePerson === 'PJ' }}
                    />
                    <Input
                      name="cpf_partner"
                      className="col-md-3"
                      label="CPF Sócio"
                      rules={{ required: typePerson === 'PJ' }}
                      onChange={event =>
                        setpartnerCPF(cpfMask(event.target.value))
                      }
                      value={partnerCPF}
                    />
                    <Input
                      name="full_name_partner"
                      className="col-md-6"
                      label="Nome Completo Sócio"
                      rules={{ required: typePerson === 'PJ' }}
                    />
                  </>
                )}
                <Select
                  className="col-md-3"
                  label="Tipo de Operação"
                  name="operation_type"
                  options={[
                    {
                      name: 'Micro Crédito',
                      value: 'Micro Crédito'
                    },
                    {
                      name: 'Pagamento de Conta',
                      value: 'Pagamento de Conta'
                    }
                  ]}
                  blank
                  defaultValue={''}
                  rules={{ required: true }}
                  disabled={typeForm === 'update'}
                />
                <Input
                  name="desired_value"
                  className="col-md-3"
                  label="Valor desejado"
                  rules={{ required: true }}
                  onChange={event =>
                    setRequestedAmount(
                      genericMaskWithTwoZeroWithPoint(event.target.value)
                    )
                  }
                  value={requestedAmount}
                  readOnly={typeForm === 'update'}
                />
                <Select
                  className="col-md-3"
                  name="number_installments"
                  label="Quantidades de Parcelas"
                  options={months.map(
                    month =>
                      month && {
                        name: month,
                        value: month
                      }
                  )}
                  onChange={event => handleSimulation(event.target.value)}
                  blank
                  defaultValue={''}
                  rules={{ required: typeForm === 'create' }}
                  disabled={typeForm === 'update'}
                />
                <Input
                  label="Total da Operação"
                  name="operation_total"
                  className="col-md-3"
                  value={operationTotal}
                  readOnly
                />
                <Input
                  label="Juros"
                  name="fees"
                  value={fees}
                  className="col-md-3"
                  readOnly
                />
                <Input
                  label="Valor Parcela"
                  className="col-md-3"
                  name="installment_value"
                  value={installmentValue}
                  readOnly
                />
                <Input
                  label="Valor a Receber"
                  className="col-md-3"
                  name="amount_receivable"
                  value={amountReceivable}
                  readOnly
                />
              </div>
              <div className="row mb-5">
                <Input
                  name="zip_code"
                  className="col-md-3"
                  label="CEP"
                  rules={{ required: !zipCode }}
                  value={zipCode}
                  onChange={event => getDataCep(event.target.value)}
                  maxLength={9}
                />
                <Input
                  name="street"
                  className=" col-md-6"
                  label="Logradouro"
                  value={address?.street}
                  rules={{ required: !address?.street }}
                />
                <Input
                  name="number"
                  className=" col-md-3"
                  label="Número"
                  value={company?.number}
                  rules={{ required: !company?.number }}
                />
              </div>
              <div className="row mb-5">
                <Input
                  name="complement"
                  className=" col-md-3"
                  label="Complemento"
                  value={company?.complement}
                  rules={{ required: !company?.complement }}
                />
                <Input
                  name="district"
                  value={address?.district}
                  className="col-md-3"
                  label="Bairro"
                  rules={{ required: !address?.district }}
                />
                <Input
                  name="city"
                  className="col-md-3"
                  label="Cidade"
                  value={address?.city}
                  rules={{ required: !address?.city }}
                />
                <Input
                  name="state"
                  value={address?.state}
                  className="col-md-3"
                  label="UF"
                  rules={{ required: !address?.state }}
                />
              </div>
              <div className="row mb-5">
                <Input
                  name="phone"
                  className="col-md-3"
                  label="Telefone"
                  value={phone}
                  onChange={event => setPhone(phoneMask(event.target.value))}
                  maxLength={15}
                  rules={{ required: true }}
                />
                <Input
                  name="email"
                  className="col-md-3"
                  label="E-mail"
                  rules={{ required: true }}
                />
                <Input name="site" className="col-md-3" label="Site" />
                <Input
                  name="instagram"
                  className="col-md-3"
                  label="Instagram"
                />
                <Input
                  name="whatsapp"
                  className="col-md-3"
                  label="WhatsApp"
                  value={whatsapp}
                  onChange={event => setWhatsapp(phoneMask(event.target.value))}
                  maxLength={15}
                />
              </div>
              <div className="row mb-5">
                <Input
                  name="card_number"
                  className="col-md-3"
                  label="Número do Cartão"
                  rules={{ required: true }}
                  maxLength={19}
                  onChange={event => handleFlagCard(event.target.value)}
                  value={cardNumber}
                  readOnly={typeForm === 'update'}
                />
                <Input
                  name="card_flag"
                  className="col-md-3"
                  label="Bandeira do Cartão"
                  value={cardFlag}
                  rules={{ required: !cardFlag.length }}
                  onChange={event => setCardFlag(event.target.value)}
                  readOnly={typeForm === 'update'}
                />
                <DatePicker
                  locale="en"
                  name="expiration_date"
                  className="col-md-3"
                  label="Data de Validade"
                  dateFormat="MM/yy"
                  placeholderText="MM/AA"
                  selected={expirateDate}
                  minDate={new Date()}
                  readOnly={typeForm === 'update'}
                  required={typeForm === 'create'}
                  customInput={
                    <IMaskInput
                      mask={Date}
                      pattern="MM/YY"
                      format={date => {
                        return moment(date).format('MM/YY')
                      }}
                      parse={value => {
                        return moment(value, 'MM/YY').toDate()
                      }}
                      validate={(date: string) => {
                        if (date.length === 5) {
                          return moment(date, 'MM/YY').isSameOrAfter(
                            moment(),
                            'month'
                          )
                        }
                        return true
                      }}
                      maxLength={5}
                      blocks={{
                        MM: {
                          mask: IMask.MaskedRange,
                          placeholderChar: 'MM',
                          from: 1,
                          to: 12,
                          autofix: true,
                          maxLength: 2
                        }
                      }}
                    />
                  }
                  onChange={date => {
                    setExpirateDate(date)
                  }}
                  showMonthYearPicker
                  controlled
                />
              </div>
              <div className="row mb-5">
                <Input
                  label="Foto do Cartão Frente"
                  name="photo_card_front"
                  className="col-md-6"
                  type="file"
                  onChange={onChangeFileHandler}
                  rules={{ required: typeForm === 'create' }}
                  disabled={typeForm === 'update'}
                />
                <Input
                  name="photo_card_verse"
                  className="col-md-6"
                  label="Foto do Cartão Verso"
                  rules={{ required: typeForm === 'create' }}
                  type="file"
                  onChange={onChangeFileHandler}
                  disabled={typeForm === 'update'}
                />

                <Input
                  name="photo_document_1"
                  className="col-md-6"
                  label="Foto Documento 1"
                  rules={{ required: typeForm === 'create' }}
                  type="file"
                  onChange={onChangeFileHandler}
                  disabled={typeForm === 'update'}
                />
                <Input
                  name="photo_document_2"
                  className="col-md-6"
                  label="Foto Documento 2"
                  type="file"
                  rules={{ required: typeForm === 'create' }}
                  onChange={onChangeFileHandler}
                  disabled={typeForm === 'update'}
                />
                <Input
                  name="photo_selfie_document"
                  className="col-md-6"
                  label="Foto Selfie Documento"
                  rules={{ required: typeForm === 'create' }}
                  type="file"
                  onChange={onChangeFileHandler}
                  disabled={typeForm === 'update'}
                />
              </div>
              <div className="row mb-5">
                <Select
                  className="col-md-3"
                  name="status"
                  label="Status"
                  options={[
                    {
                      name: 'Novo',
                      value: 'Novo'
                    },
                    {
                      name: 'Em Andamento',
                      value: 'Em Andamento'
                    },
                    {
                      name: 'Cancelado',
                      value: 'Cancelado'
                    },
                    {
                      name: 'Finalizado',
                      value: 'Finalizado'
                    }
                  ]}
                  defaultValue="Novo"
                  rules={{ required: true }}
                  onChange={event => setTypePerson(event.target.value)}
                />
                <Input
                  name="payment_proof"
                  className="col-md-6"
                  label="Comprovante de Pagamento"
                  type="file"
                  onChange={onChangeFileHandler}
                />
              </div>
              <div className="row">
                <Textarea
                  name="observation"
                  className=" col-md-12"
                  label="Observações"
                  style={{ minHeight: 120 }}
                  defaultValue=""
                />
              </div>
            </FormContainer>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </>
      </Form>
      <Alert
        message={
          'Deseja enviar e-mail informando sobre a alteração de status ?'
        }
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={handlerClickButtonConfirmAlert}
        isActive={alert.isActive}
      />
    </>
  )
}
