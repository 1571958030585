import React from 'react'
import OperationIndexesList from '../../pages/Financial/OperationIndexes/List'
import OperationIndexesUpdate from '../../pages/Financial/OperationIndexes/Update'
import OperationIndexesCreate from '../../pages/Financial/OperationIndexes/Create'
import OperationIndexesView from '../../pages/Financial/OperationIndexes/View'
import PrivateRoute from '../PrivateRoute'
import { CustomSwitch } from '../../components/CustomSwitch'

export const OperationIndexesRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoute
      path="/financial/operationIndexes"
      exact
      component={OperationIndexesList}
    />
    <PrivateRoute
      path="/financial/operationIndexes/create"
      component={OperationIndexesCreate}
      exact
    />
    <PrivateRoute
      path="/financial/operationIndexes/view/:id"
      component={OperationIndexesView}
      exact
    />
    <PrivateRoute
      path="/financial/operationIndexes/update/:id"
      component={OperationIndexesUpdate}
      exact
    />
  </CustomSwitch>
)
