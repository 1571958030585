import React, { useCallback, useEffect, useState } from 'react'
import { CardSimple } from '../../components/CardSimple'
import { PageTitle } from '../../assets/layout/core'
import {
  faArrowAltCircleRight,
  faArrowCircleRight,
  faCheck,
  faHourglassStart,
  faTimes,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import api from '../../services/api'
import { useToast } from '../../hooks/toast'
import { useLoading } from '../../hooks/loading'
import { BRL } from '../../utlis/mask'
import moment from 'moment'
import { Loading } from '../../components/Loading'

export const Dashboard: React.FC = () => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading, loading } = useLoading()
  const startMonth = moment().startOf('M').format()
  const endMonth = moment().endOf('M').format()
  const [newClients, setNewClients] = useState({
    quantity: '0',
    value: '0'
  })
  const [inProgressClients, setInProgressClients] = useState({
    quantity: '0',
    value: '0'
  })
  const [finishedClients, setFinishedClients] = useState({
    quantity: '0',
    value: '0'
  })
  const [cancelledClients, setCancelledClients] = useState({
    quantity: '0',
    value: '0'
  })

  const sumTotal = (data: any[]) => {
    let price = '0'
    for (const item of data) {
      const operation_total = item.operation_total
      price = BRL(price).add(BRL(operation_total).format()).format()
    }
    return BRL(price, {
      symbol: 'R$ '
    }).format()
  }

  const loadNewClients = useCallback(async () => {
    try {
      const { data } = await api.get(
        '/dataTable?entity=Client&source=commercial%2Fclients&keyword=&page=1&perPage=50&orderByField=&searchParameters[]=%7B%22status%22:%22Novo%22%7D&orderBy=id&parentId=&entityId='
      )
      const price = sumTotal(data.items)
      setNewClients({
        quantity: `${data.totalItens}`,
        value: price
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os registros',
        description:
          'Ocorreu um erro ao carregar os registros, por favor, tente novamente.'
      })
    }
  }, [addToast])

  const loadInProgressClients = useCallback(async () => {
    try {
      const { data } = await api.get(
        '/dataTable?entity=Client&source=commercial%2Fclients&keyword=&page=1&perPage=50&orderByField=&searchParameters[]=%7B%22status%22:%22Em+Andamento%22%7D&orderBy=id&parentId=&entityId='
      )
      const price = sumTotal(data.items)
      setInProgressClients({
        quantity: `${data.totalItens}`,
        value: price
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os registros',
        description:
          'Ocorreu um erro ao carregar os registros, por favor, tente novamente.'
      })
    }
  }, [addToast])

  const loadFinishedClients = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/dataTable?entity=Client&source=commercial%2Fclients&keyword=&page=1&perPage=50&orderByField=&searchParameters[]=%7B%22start_date%22:%22${startMonth}%22,%22end_date%22:%22${endMonth}%22,%22status%22:%22Finalizado%22%7D&orderBy=id&parentId=&entityId=`
      )
      const price = sumTotal(data.items)
      setFinishedClients({
        quantity: `${data.totalItens}`,
        value: price
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os registros',
        description:
          'Ocorreu um erro ao carregar os registros, por favor, tente novamente.'
      })
    }
  }, [addToast, endMonth, startMonth])

  const loadCancelledClients = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/dataTable?entity=Client&source=commercial%2Fclients&keyword=&page=1&perPage=50&orderByField=&searchParameters[]=%7B%22start_date%22:%22${startMonth}%22,%22end_date%22:%22${endMonth}%22,%22status%22:%22Cancelado%22%7D&orderBy=id&parentId=&entityId=`
      )
      const price = sumTotal(data.items)
      setCancelledClients({
        quantity: `${data.totalItens}`,
        value: price
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar os registros',
        description:
          'Ocorreu um erro ao carregar os registros, por favor, tente novamente.'
      })
    }
  }, [addToast, endMonth, startMonth])

  useEffect(() => {
    activeLoading()
    ;(async () => {
      await loadNewClients()
      await loadInProgressClients()
      await loadFinishedClients()
      await loadCancelledClients()
      disableLoading()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loading ? (
    <Loading isActive={loading} />
  ) : (
    <div className="container-fluid">
      <PageTitle>Painel de Controle</PageTitle>
      <div className="row gy-5 g-xl-8">
        <div className="col-md-3">
          <CardSimple
            primaryColor="success"
            text="Clientes Novos"
            number={newClients.quantity}
            subNumber={newClients.value}
            footer={{
              link: '/commercial/clients?status=novo',
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faUsers
            }}
          />
        </div>
        <div className="col-md-3">
          <CardSimple
            primaryColor="warning"
            text="Clientes Em Andamento"
            number={inProgressClients.quantity}
            subNumber={inProgressClients.value}
            footer={{
              link: '/commercial/clients?status=em andamento',
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faHourglassStart
            }}
          />
        </div>
        <div className="col-md-3">
          <CardSimple
            primaryColor="primary"
            text="Clientes Finalizadas"
            number={finishedClients.quantity}
            subNumber={finishedClients.value}
            footer={{
              link: `/commercial/clients?status=finalizado&date=${startMonth}&endDate=${endMonth}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faCheck
            }}
          />
        </div>
        <div className="col-md-3">
          <CardSimple
            primaryColor="danger"
            text="Clientes Cancelados"
            number={cancelledClients.quantity}
            subNumber={cancelledClients.value}
            footer={{
              link: `/commercial/clients?status=cancelado&date=${startMonth}&endDate=${endMonth}`,
              text: 'Mais Detalhes',
              iconSettings: {
                icon: faArrowAltCircleRight,
                style: {
                  size: 'lg'
                }
              }
            }}
            iconSettings={{
              icon: faTimes
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
