import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { CSSProperties } from 'styled-components'
import { Container } from './styles'

interface CardSimpleProps {
  primaryColor: string
  classname?: string
  textColor?: string
  text: string
  number: string
  subNumber?: string
  iconSettings?: {
    icon: IconDefinition
    style?: {
      size?: SizeProp
      color?: string
    }
  }
  style?: CSSProperties
  footer?: {
    link?: string
    handleClick?: () => void
    text: string
    iconSettings?: {
      icon: IconDefinition
      style?: {
        size?: SizeProp
        color?: string
      }
    }
  }
}

export const CardSimple: React.FC<CardSimpleProps> = ({
  primaryColor,
  iconSettings,
  textColor,
  footer,
  style,
  classname,
  number,
  text,
  subNumber
}) => {
  return (
    <Container
      className={`card p-0 border border-${primaryColor} ${classname || ''}`}
      style={style}
    >
      <div className={`bg-${primaryColor}`}>
        <div className="card-body d-flex justify-content-between">
          <FontAwesomeIcon
            icon={iconSettings?.icon}
            size={iconSettings?.style?.size || '5x'}
            color={iconSettings?.style?.color || '#fff'}
          />
          <div
            className={`d-flex flex-column align-items-end text-${
              textColor || 'white'
            }`}
          >
            <span className="fw-bolder fs-2hx">{number}</span>
            {subNumber && (
              <span className="fw-bolder fs-medium">{subNumber}</span>
            )}
            <span className="fw-bolder fs-6">{text}</span>
          </div>
        </div>
      </div>
      <div className="py-4 px-9 ">
        <a
          href={footer?.link}
          onClick={footer?.handleClick}
          className={`text-${primaryColor} d-flex justify-content-between`}
        >
          {footer?.text}
          <FontAwesomeIcon
            icon={footer?.iconSettings?.icon}
            size={footer?.iconSettings?.style?.size || '5x'}
            color={footer?.iconSettings?.style?.color}
            className={`text-${primaryColor}`}
          />
        </a>
      </div>
    </Container>
  )
}
