/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState } from 'react'
import logo from '../../assets/media/logos/logo-b2b.png'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerWrapperContent,
  WrapperContent
} from './styles'
import Form, { Input, Select } from '../../components/Form'
import { Stepper } from '../../components/Stepper'
import { useLoading } from '../../hooks/loading'
import api from '../../services/api'
import { useToast } from '../../hooks/toast'
import {
  convertValueMaskInNumberWithTwoZero,
  genericMaskWithTwoZeroWithPoint
} from '../../utlis/mask'
import currency from 'currency.js'
import { Loading } from '../../components/Loading'
import { Link } from 'react-router-dom'
type OperationIndexData = {
  id?: string
  title?: string
  index?: string
  number_installments?: string
  requested_amount?: string
}

export const Simulation: React.FC = () => {
  const { activeLoading, disableLoading, loading } = useLoading()
  const { addToast } = useToast()
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const [operationTotal, setOperationTotal] = useState('0')
  const [fees, setFees] = useState('0')
  const [installmentValue, setInstallmentValue] = useState('0')
  const [amountReceivable, setAmountReceivable] = useState('0')
  const [requestedAmount, setRequestedAmount] = useState('0,00')
  const [operationType, setOperationType] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [numberInstallments, setNumberInstallments] = useState<string>()
  const BRL = (value: any) =>
    currency(value, {
      symbol: '',
      decimal: ',',
      separator: '.'
    })

  const onSubmit = useCallback(
    async (dataForm: OperationIndexData) => {
      activeLoading()
      try {
        const { number_installments } = dataForm
        const { data } = await api.get<OperationIndexData>(
          `/financial/public/operationIndexes/findByNumberInstallment/${number_installments}`
        )
        const index = Number(data.index.replaceAll(',', '.'))
        const numberRequestedAmount =
          convertValueMaskInNumberWithTwoZero(requestedAmount)
        const totalValue = numberRequestedAmount * index
        setOperationTotal(BRL(totalValue).format())
        setFees(BRL(totalValue).subtract(numberRequestedAmount).format())
        setInstallmentValue(
          BRL(totalValue).divide(Number(dataForm.number_installments)).format()
        )
        setAmountReceivable(BRL(dataForm.requested_amount).format())
        setShowResults(true)
        disableLoading()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar indice',
          description:
            'Ocorreu um erro ao buscar o indice, por favor, tente novamente.'
        })
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading, requestedAmount]
  )

  const handleSimulation = useCallback(
    async (number_installments: string) => {
      setNumberInstallments(number_installments)
      activeLoading()
      try {
        const { data } = await api.get<OperationIndexData>(
          `/financial/public/operationIndexes/findByNumberInstallment/${number_installments}`
        )
        const index = Number(data.index.replaceAll(',', '.'))
        const numberRequestedAmount =
          convertValueMaskInNumberWithTwoZero(requestedAmount)
        const totalValue = numberRequestedAmount * index
        setOperationTotal(BRL(totalValue).format())
        setFees(BRL(totalValue).subtract(numberRequestedAmount).format())
        setInstallmentValue(
          BRL(totalValue).divide(Number(number_installments)).format()
        )
        setAmountReceivable(BRL(requestedAmount).format())
        disableLoading()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar indice',
          description:
            'Ocorreu um erro ao buscar o indice, por favor, tente novamente.'
        })
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading, requestedAmount]
  )

  return (
    <div>
      <Loading isActive={loading} />
      <div className="row gy-5 g-xl-8">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="/">
                <img
                  alt="Logo"
                  src={logo}
                  className="h-55px w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
          </WrapperContent>
        </ContainerHeader>
      </div>
      <ContainerWrapperContent>
        <div className="row gy-5 g-xl-8 w-100">
          <div className="card p-10">
            <Stepper
              steps={[
                {
                  status: 'current',
                  step: '1',
                  title: 'Simulação'
                },
                {
                  status: '',
                  step: '2',
                  title: 'Dados Pessoais'
                },
                {
                  status: '',
                  step: '3',
                  title: 'Dados do Cartão'
                },
                {
                  status: '',
                  step: '4',
                  title: 'Termos e Condições'
                }
              ]}
            />
            <h1>Simule Agora</h1>
            <div className="form">
              <Form className="form" onSubmit={onSubmit}>
                <div className="row mb-5">
                  <Select
                    className="col-md-3"
                    label="Tipo de Operação"
                    name="operation_type"
                    onChange={event => setOperationType(event.target.value)}
                    options={[
                      {
                        name: 'Micro Crédito',
                        value: 'Micro Crédito'
                      },
                      {
                        name: 'Pagamento de Conta',
                        value: 'Pagamento de Conta'
                      }
                    ]}
                    value={operationType}
                    blank
                    defaultValue={''}
                    rules={{ required: true }}
                  />
                  <Input
                    placeholder="0,00"
                    className="col-md-4"
                    label="Valor Solicitado"
                    name="requested_amount"
                    rules={{ required: true }}
                    onChange={event =>
                      setRequestedAmount(
                        genericMaskWithTwoZeroWithPoint(event.target.value)
                      )
                    }
                    value={requestedAmount}
                  />
                  <Select
                    className="col-md-3"
                    name="number_installments"
                    label="Número de Parcelas"
                    options={months.map(
                      month =>
                        month && {
                          name: month,
                          value: month
                        }
                    )}
                    onChange={event => handleSimulation(event.target.value)}
                    value={numberInstallments}
                    blank
                    defaultValue={''}
                    rules={{ required: true }}
                  />

                  <div className="text-center col-md-2 d-flex align-items-end justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary mb-5"
                    >
                      <span className="indicator-label">Simule</span>
                    </button>
                  </div>
                </div>
              </Form>
              {showResults && (
                <>
                  <div className="row mb-5">
                    <Input
                      label="Total da Operação"
                      name="operation_total"
                      className="col-md-3"
                      value={operationTotal}
                      readOnly={true}
                    />
                    <Input
                      label="Juros"
                      name="fees"
                      value={fees}
                      className="col-md-3"
                      readOnly={true}
                    />
                    <Input
                      label="Valor Parcela"
                      className="col-md-3"
                      name="installment_value"
                      value={installmentValue}
                      readOnly={true}
                    />
                    <Input
                      label="Valor a Receber"
                      className="col-md-3"
                      name="amount_receivable"
                      value={amountReceivable}
                      readOnly={true}
                    />
                  </div>
                  <div className="row">
                    <div className="d-flex justify-content-end">
                      <Link
                        to={{
                          pathname: '/simulation/client',
                          state: {
                            number_installments: numberInstallments,
                            desired_value: requestedAmount,
                            operation_total: operationTotal,
                            fees,
                            installment_value: installmentValue,
                            amount_receivable: amountReceivable,
                            operation_type: operationType,
                            steps: {
                              1: 'completed',
                              2: 'current',
                              3: ''
                            }
                          }
                        }}
                        type="button"
                        className="btn btn-primary btn-lg"
                      >
                        Próximo
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </ContainerWrapperContent>
    </div>
  )
}

export default Simulation
