import styled from 'styled-components'

export const Container = styled.div`
  .calculator {
    position: absolute;
    top: 6vh;
    right: 1vw;
    height: 30rem;
    width: 24rem;
    .react-calculator {
      .result-panel {
        overflow: hidden;
      }
    }
    .react-calc.button-panel.row {
      margin: 0;
      .s1.column {
        padding: 0;
        .last {
          background-color: #009ef7 !important;
        }
      }
    }
  }
`

export const AvatarCircle = styled.img`
  width: 40px;
  height: 40px;

  object-fit: cover;
`
export const WrapperButtons = styled.div`
  @media (min-width: 991px) {
    .container-fluid {
      display: flex;
      align-items: center;
    }
    div.page-logo {
      width: auto !important;
    }
    .page-header
      .page-header-top
      .top-menu
      .navbar-nav
      li.dropdown
      .dropdown-toggle:active,
    .page-header
      .page-header-top
      .top-menu
      .navbar-nav
      li.dropdown
      .dropdown-toggle:focus,
    .page-header
      .page-header-top
      .top-menu
      .navbar-nav
      li.dropdown
      .dropdown-toggle:hover {
      background-color: #333 !important;
    }
    .nav li a:focus,
    .nav li a:hover {
      background-color: #333333 !important;
    }
    li.dropdown {
      margin-left: 20px !important;
    }
  }
  span {
    color: #fff !important;
    &:hover {
      color: #ccc !important;
    }
  }
  i {
    color: #fff !important;
    &:hover {
      color: #ccc !important;
    }
  }
  @media (max-width: 480px) {
    li.dropdown-user .dropdown-toggle {
      background-color: #333333 !important;
    }
  }
`
export const ContainerHeader = styled.div`
  .page-header-top {
    background: #333333;
  }
  @media (min-width: 991px) {
    height: 100px !important;
    .header-fixed {
      height: auto !important;
      position: fixed;
      z-index: 3;
      width: 100% !important;
    }
    .container-fluid {
      display: flex;
      align-items: center;
      height: 100px;
    }
    div.page-logo {
      width: auto !important;
      margin-left: -45px;
    }
    div.page-header-top {
      height: 100%;
    }
    div.container-flex {
      display: flex;
      align-items: center;
    }
    div.top-menu {
      margin: 0 0 0 auto !important;
    }
    div.page-header-menu {
      margin-left: 45px;
    }
  }
  @media (max-width: 480px) {
    div.top-menu {
      background-color: #333333 !important;
    }
  }
`
