import styled from 'styled-components'

export const Container = styled.div`
  .menu-link {
    color: #f4f4f4 !important;
  }
  .menu-title {
    color: #f4f4f4 !important;
  }
`
