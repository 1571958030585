import React, { useCallback, useRef, useState } from 'react'
import logo from '../../assets/media/logos/logo-b2b.png'
import banner from '../../assets/media/landing.svg'
import ilustration1 from '../../assets/media/illustrations/sigma-1/1-dark.png'
import ilustration2 from '../../assets/media/illustrations/sigma-1/2-dark.png'
import ilustration3 from '../../assets/media/illustrations/sigma-1/3-dark.png'
import { useMediaQuery } from 'react-responsive'
import {
  ContainerHeader,
  ContainerLogo,
  ContainerMenu,
  ContainerWrapperContent,
  WrapperContent,
  Link,
  Button,
  ContainerBanner,
  ContainerTitle,
  ContainerMenuMobile
} from './styles'
import { useHistory } from 'react-router-dom'

export const Home: React.FC = () => {
  const infoContainerRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: 1224 })
  const [isOpen, setIsOpen] = useState('')
  const handleFocusContainer = useCallback(() => {
    setIsOpen('')
    if (infoContainerRef.current) {
      infoContainerRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [])
  const handleForward = useCallback(() => {
    setIsOpen('')
    history.push('/simulation')
  }, [history])

  return (
    <div>
      <div className="row gy-5 g-xl-8">
        <ContainerHeader>
          <WrapperContent>
            <ContainerLogo>
              <a href="#">
                <img
                  alt="Logo"
                  src={logo}
                  className="h-55px w-150px logo"
                  style={{ objectFit: 'cover' }}
                />
              </a>
            </ContainerLogo>
            {isDesktop ? (
              <ContainerMenu>
                <Link className="menu menu-lg-rounded menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                  <div
                    className="menu-item menu-lg-down-accordion me-lg-1"
                    onClick={handleFocusContainer}
                  >
                    <span className="menu-link py-3">
                      <span className="menu-title">Como funciona</span>
                      <span className="menu-arrow d-lg-none"></span>
                    </span>
                  </div>
                </Link>
                <Link>
                  <div>
                    <Button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleForward}
                    >
                      <span>SIMULE AGORA</span>
                    </Button>
                  </div>
                </Link>
              </ContainerMenu>
            ) : (
              <span
                className="fa fa-bars fa-2x"
                onClick={() => setIsOpen(isOpen === 'show' ? 'hidden' : 'show')}
              ></span>
            )}
          </WrapperContent>
        </ContainerHeader>
        {!isDesktop && (
          <>
            <ContainerMenuMobile isShow={isOpen}>
              <Link className="menu menu-lg-rounded menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary fw-bold my-5 my-lg-0 align-items-stretch">
                <div
                  className="menu-item menu-lg-down-accordion me-lg-1"
                  onClick={handleFocusContainer}
                >
                  <span className="menu-link py-3">
                    <span className="menu-title">Como funciona</span>
                  </span>
                </div>
              </Link>
              <Link>
                <div>
                  <Button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleForward}
                  >
                    <span>SIMULE AGORA</span>
                  </Button>
                </div>
              </Link>
            </ContainerMenuMobile>
          </>
        )}
      </div>

      <div className="bg-white">
        <ContainerWrapperContent>
          <ContainerBanner>
            <ContainerTitle>
              <h2 className="fw-bolder fs-1 text-gray-800">
                Lorem ipsum dolor sit amet
              </h2>
              <h2 className="fw-bolder fs-1 text-gray-800">
                consectetur adipiscing elit
              </h2>
              <Button
                type="button"
                className="btn btn-primary w-100 d-flex justify-content-center"
                onClick={handleForward}
              >
                Faça uma simulação
              </Button>
            </ContainerTitle>
            <img src={banner} className="img wv-100 h-100" />
          </ContainerBanner>
          <div className="row gy-5 g-xl-8 w-100 " ref={infoContainerRef}>
            <div className="d-flex flex-column align-items-center">
              <div style={{ marginBottom: '90px' }}>
                <h1
                  className="fw-bolder fs-1 text-gray-800"
                  style={{
                    textAlign: 'center',
                    marginBottom: '0.5rem'
                  }}
                >
                  Lorem ipsum dolor sit amet
                </h1>
                <h5
                  className="fw-bolder fs-4 text-gray-800"
                  style={{ textAlign: 'center' }}
                >
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua
                </h5>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="fw-bolder fs-1 text-gray-800">
                    Lorem ipsum dolor sit amet
                  </h3>
                  <p className="fw-bolder fs-6 text-gray-800">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
                <div
                  className="col-sm-6 d-flex justify-content-center"
                  style={{ marginBottom: '130px' }}
                >
                  <img
                    alt="Logo"
                    src={ilustration1}
                    className="h-200px "
                    style={{ objectFit: 'cover' }}
                  />
                </div>
                <div className="col-sm-6 d-flex justify-content-center mb-10">
                  <img
                    alt="Logo"
                    src={ilustration2}
                    className="h-200px "
                    style={{ objectFit: 'cover' }}
                  />
                </div>
                <div
                  className="col-sm-6 d-flex flex-column align-tems-end"
                  style={{ marginBottom: '130px' }}
                >
                  <h3 className="fw-bolder fs-1 text-gray-800">
                    Consectetur adipiscing elit.
                  </h3>
                  <p className="fw-bolder fs-6 text-gray-800 ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
                <div className="col-sm-6 mb-10">
                  <h3 className="fw-bolder fs-1 text-gray-800">
                    Excepteur sint occaecat cupidatat non proident
                  </h3>
                  <p className="fw-bolder fs-6 text-gray-800">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                  </p>
                </div>
                <div
                  className="col-sm-6 d-flex justify-content-center"
                  style={{ marginBottom: '130px' }}
                >
                  <img
                    alt="Logo"
                    src={ilustration3}
                    className="h-200px"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </ContainerWrapperContent>
      </div>
    </div>
  )
}

export default Home
